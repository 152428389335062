import {
    ArrowDownTrayIcon,
    ArrowTopRightOnSquareIcon,
    EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { CalendarDate, formatCurrency } from "@sp-crm/core";
import React from "react";
import { Link } from "react-router-dom";
import { isUrl } from "util/uri";
import { InvoiceGenerateLink } from "./invoice-generate-link";
import { ClientInvoice, CommunityInvoice } from "./invoice-helpers";

interface RowProps {
    invoice: ClientInvoice | CommunityInvoice;
    canModifyInvoices: boolean;
    refetch: () => void;
    descriptionBehavior: "client" | "community";
    onEdit: (invoice: ClientInvoice | CommunityInvoice) => void;
    onSend: (invoice: ClientInvoice | CommunityInvoice) => void;
}
export const InvoiceRow: React.FC<RowProps> = props => {
    const { invoice, refetch, canModifyInvoices, descriptionBehavior, onEdit, onSend } =
        props;

    const isExternallyManaged = invoice.isExternallyManaged;

    const enableEdit = React.useCallback(
        (
            e:
                | React.MouseEvent<HTMLTableRowElement>
                | React.MouseEvent<HTMLAnchorElement>,
        ) => {
            e.preventDefault();
            if (canModifyInvoices) {
                onEdit(invoice);
            }
        },
        [invoice, onEdit, canModifyInvoices],
    );
    const enableSend = React.useCallback(
        (
            e:
                | React.MouseEvent<HTMLTableRowElement>
                | React.MouseEvent<HTMLAnchorElement>,
        ) => {
            e.preventDefault();
            onSend(invoice);
        },
        [invoice, onSend],
    );
    const download = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
    }, []);

    return (
        <>
            <tr className="hover:bg-brand-100">
                {!isExternallyManaged ? (
                    <td scope="col" className="pl-6 pr-2 py-4 whitespace-nowrap ">
                        {canModifyInvoices ? (
                            <a
                                href="#"
                                onClick={enableEdit}
                                className="font-semibold text-blue-600 hover:underline">
                                Edit
                            </a>
                        ) : null}
                    </td>
                ) : (
                    <td />
                )}
                {!isExternallyManaged ? (
                    <td scope="col" className="px-2 py-4 whitespace-nowrap ">
                        {invoice.fileId ? (
                            <a
                                className="font-semibold text-blue-600 hover:underline"
                                onClick={download}
                                href={`/api/invoices/${invoice.id}`}
                                target="_blank"
                                rel="noreferrer">
                                <p className="flex items-center space-x-1">
                                    <ArrowDownTrayIcon className="h-5 w-5" />
                                    <span>Download</span>
                                </p>
                            </a>
                        ) : (
                            <InvoiceGenerateLink invoice={invoice} refetch={refetch} />
                        )}
                    </td>
                ) : (
                    <td />
                )}
                {
                    <td scope="col" className="px-2 py-4 whitespace-nowrap ">
                        <a
                            className="font-semibold text-blue-600 hover:underline"
                            onClick={enableSend}
                            href={`/api/invoices/${invoice.id}`}
                            target="_blank"
                            rel="noreferrer">
                            <p className="flex items-center space-x-1">
                                <EnvelopeIcon className="h-5 w-5" />
                                <span>Email</span>
                            </p>
                        </a>
                    </td>
                }
                <td scope="col" className="px-2 py-4 whitespace-nowrap ">
                    {invoice.externalReference && isUrl(invoice.externalReference) ? (
                        <a
                            className="font-semibold text-blue-600 hover:underline"
                            href={invoice.externalReference}
                            target="_blank"
                            rel="noreferrer">
                            <p className="flex items-center space-x-1">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                <span>Link</span>
                            </p>
                        </a>
                    ) : null}
                </td>
                <td scope="col" className="px-6 py-4 whitespace-nowrap ">
                    <InvoiceStatus invoice={invoice} />
                </td>
                <td scope="col" className="px-6 py-4 whitespace-nowrap ">
                    {invoice.serial}
                </td>
                <td scope="col" className="px-6 py-4 whitespace-nowrap ">
                    {descriptionBehavior === "client" ? (
                        <Link to={`/clients/show/${invoice.clientId}`}>
                            {(invoice as CommunityInvoice).clientName ?? "(no name)"}
                        </Link>
                    ) : (invoice as ClientInvoice).community ? (
                        <Link
                            to={`/communities/show/${
                                (invoice as ClientInvoice).community.id
                            }`}>
                            {(invoice as ClientInvoice).community.name ?? "(no name)"}
                        </Link>
                    ) : null}
                </td>

                <td scope="col" className="px-6 py-4 whitespace-nowrap ">
                    {invoice.sentDate ? (
                        CalendarDate.parse(invoice.sentDate)
                            .map(d => d.humanFriendly())
                            .getOrElse("??")
                    ) : (
                        <em>Not sent</em>
                    )}
                </td>
                <td scope="col" className="px-6 py-4 whitespace-nowrap ">
                    {invoice.paidDate ? (
                        CalendarDate.parse(invoice.paidDate)
                            .map(d => d.humanFriendly())
                            .getOrElse("??")
                    ) : (
                        <em>Not paid</em>
                    )}
                </td>
                <td scope="col" className="px-6 py-4 whitespace-nowrap ">
                    {invoice.dueDate ? (
                        CalendarDate.parse(invoice.dueDate)
                            .map(d => d.humanFriendly())
                            .getOrElse("??")
                    ) : (
                        <em>No due date</em>
                    )}
                </td>
                <td
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap text-lg font-bold text-right">
                    {formatCurrency(invoice.amountCents / 100)}
                </td>
            </tr>
        </>
    );
};

interface StatusProps {
    invoice: ClientInvoice | CommunityInvoice;
}
const InvoiceStatus: React.FC<StatusProps> = props => {
    const { invoice } = props;

    if (invoice.status === "new") {
        return (
            <div className="bg-blue-100 text-blue-800 px-2 py-1 rounded text-xs font-semibold inline">
                NEW
            </div>
        );
    }
    if (invoice.status === "void") {
        return (
            <div className="bg-gray-100 text-gray-800 px-2 py-1 rounded text-xs font-semibold inline">
                VOID
            </div>
        );
    }
    if (invoice.status === "paid") {
        return (
            <div className="bg-green-100 text-green-800 px-2 py-1 rounded text-xs font-semibold inline">
                PAID
            </div>
        );
    }
    if (invoice.status === "sent") {
        const partiallyPaid =
            invoice.totalPaidCents && invoice.totalPaidCents < invoice.amountCents;

        return (
            <div className="space-y-1">
                <div
                    className={`${
                        partiallyPaid
                            ? "bg-lime-100 text-lime-800"
                            : "bg-yellow-100 text-yellow-800"
                    } px-2 py-1 rounded text-xs font-semibold inline`}>
                    {partiallyPaid ? "PARTIALLY PAID" : "SENT"}
                </div>
                {partiallyPaid ? (
                    <p className="text-sm italic text-gray-600 pl-1">
                        {formatCurrency(invoice.remainingBalanceCents / 100)} due
                    </p>
                ) : null}
            </div>
        );
    }
    return <span>status</span>;
};
