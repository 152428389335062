import { ActionType } from "@sp-crm/core";
import React from "react";
import { useIsAllowed } from "store/selectors/hooks";

interface IsAllowedProps {
    actionType: ActionType;
    children: React.ReactNode;
}

export const IsAllowed: React.FC<IsAllowedProps> = props => {
    const { actionType, children } = props;

    const isAllowed = useIsAllowed(actionType);

    return isAllowed ? <>{children}</> : null;
};
