import {
    CheckCircleIcon,
    CloudArrowUpIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import {
    EmailEntityId,
    SentMessageId,
    SentMessageType,
    sentMessageTypeValues,
} from "@sp-crm/core";
import { ClientPersonaLink } from "components/clients/list-client/client-persona-link";
import { defaultLinkStyle } from "components/ui/link";
import React from "react";
import { Link } from "react-router-dom";
import { useFeature } from "store/selectors/hooks";
import {
    GetMessagesForEntityQuery,
    useGetMessagesForEntityQuery,
} from "../../generated/graphql";
import { isoDateStringToHumanReadable } from "../../util/date/locale-date-format";
import { ErrorMessage } from "../ui/error-message";
import { WarningIcon } from "../ui/icon";
import { Spinner } from "../ui/spinner";
import { SentMessageDetail } from "./sent-detail";

const humanReadableWellFormedMessageType = (input: SentMessageType): string => {
    const mapping: Record<SentMessageType, string> = {
        ClientIntake: "Intake",
        ClientIntakeFax: "Intake (fax)",
        CommunityComparison: "Community Comparison",
        Email: "",
        Invoice: "Invoice",
        DisclosureRequest: "Disclosure Request",
        DisclosureReceipt: "Disclosure Receipt",
        HostedFormRequest: "Share Input Form",
        TestOnlyMessage: "",
        SignatureCompletion: "Signature Completion",
        SignatureRequest: "Signature Request",
        BulkMessage: "Outreach",
    };
    return mapping[input] || "";
};

const humanReadableMessageType = (input: string | null | undefined) => {
    if (sentMessageTypeValues.includes(input as SentMessageType)) {
        return humanReadableWellFormedMessageType(input as SentMessageType);
    }
    return "";
};

interface StatusIconProps {
    status: string;
    latestEventStatus: string | null | undefined;
    messageType: SentMessageType;
}

interface SentMessagesProps {
    entityId: EmailEntityId;
    showClient: boolean;
    showCommunity: boolean;
}

export const StatusIcon: React.FC<StatusIconProps> = props => {
    const { status, latestEventStatus, messageType } = props;
    if (status === "warning") {
        return <WarningIcon className="text-yellow-500 w-6 h-6 mr-2" />;
    }
    if (status === "error") {
        return <ExclamationTriangleIcon className="text-red-500 w-6 h-6 mr-2" />;
    }
    if (status === "success") {
        return <CheckCircleIcon className="text-green-400 w-6 h-6 mr-2" />;
    }
    if (
        latestEventStatus === "fax-send-failure" ||
        latestEventStatus === "fax-delivery-failure"
    ) {
        return <ExclamationTriangleIcon className="text-red-500 w-6 h-6 mr-2" />;
    }
    if (latestEventStatus === "fax-delivery-success") {
        return <CheckCircleIcon className="text-green-400 w-6 h-6 mr-2" />;
    }
    if (!latestEventStatus && messageType === "ClientIntakeFax") {
        return <CloudArrowUpIcon className="text-gray-200 w-6 h-6 mr-2" />;
    }
    return null;
};

export const SentMessages: React.FC<SentMessagesProps> = props => {
    const { showClient, showCommunity } = props;

    const query = useGetMessagesForEntityQuery({ id: props.entityId });

    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="SentMessages">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }

    return (
        <SentMessagesTable
            messages={query.data.getMessagesForEntity}
            showClient={showClient}
            showCommunity={showCommunity}
        />
    );
};

interface SentMessagesTableProps {
    messages: GetMessagesForEntityQuery["getMessagesForEntity"];
    showClient: boolean;
    showCommunity: boolean;
}

export const SentMessagesTable: React.FC<SentMessagesTableProps> = props => {
    const { messages } = props;
    const enhancedMode = useFeature("enhancedSentMessageView");
    const [viewing, setViewing] = React.useState<SentMessageId | null>();
    const closeWindow = React.useCallback(() => setViewing(null), []);

    const showCommunity = props.showCommunity && messages.some(e => !!e.intakeCommunity);
    const showClient = props.showClient && messages.some(e => !!e.intakeClient);
    return (
        <div className="flex flex-col">
            {viewing ? (
                <SentMessageDetail onDismiss={closeWindow} messageId={viewing} />
            ) : null}
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="p-1 md:px-6 md:py-3 text-left text-base font-medium text-gray-600 uppercase">
                                        To
                                    </th>
                                    <th className="p-1 md:px-6 md:py-3  text-left text-base font-medium text-gray-600 uppercase">
                                        Subject
                                    </th>
                                    <th className="p-1 md:px-6 md:py-3  text-left text-base font-medium text-gray-600 uppercase">
                                        Sent On
                                    </th>
                                    <th className="p-1 md:px-6 md:py-3  text-left text-base font-medium text-gray-600 uppercase">
                                        Type
                                    </th>
                                    {showCommunity ? (
                                        <th className="p-1 md:px-6 md:py-3  text-left text-base font-medium text-gray-600 uppercase">
                                            Community
                                        </th>
                                    ) : null}
                                    {showClient ? (
                                        <th className="p-1 md:px-6 md:py3 text-left text-base font-medium text-gray-600 uppercase">
                                            Client
                                        </th>
                                    ) : null}
                                    {enhancedMode ? (
                                        <>
                                            <th className="p-1 md:px-6 md:py3 text-left text-base font-medium text-gray-600 uppercase">
                                                Times Opened
                                            </th>
                                            <th className="p-1 md:px-6 md:py3 text-left text-base font-medium text-gray-600 uppercase">
                                                Links Clicked
                                            </th>
                                        </>
                                    ) : null}
                                    <th className="p-1 md:px-6 md:py3 text-left text-base font-medium text-gray-600 uppercase">
                                        Attachments
                                    </th>
                                    <th className="relative px-6 py-3">
                                        <span className="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {messages.length === 0 ? (
                                    <tr>
                                        <td
                                            className="p-1 md:px-6 md:py-3  text-center text-base"
                                            colSpan={showCommunity ? 4 : 5}>
                                            <em>No messages sent yet</em>
                                        </td>
                                    </tr>
                                ) : (
                                    messages.map(m => {
                                        const openedEvents = m.events
                                            ? m.events.filter(
                                                  e => e.eventType === "opened",
                                              )
                                            : [];
                                        const attachments = m.attachments || [];
                                        return (
                                            <tr
                                                key={m.id}
                                                className="hover:bg-brand-100"
                                                onClick={() => setViewing(m.id)}>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                    <div className="flex items-center">
                                                        <StatusIcon
                                                            messageType={
                                                                m.messageType as SentMessageType
                                                            }
                                                            status={m.status}
                                                            latestEventStatus={
                                                                m.latestEventStatus
                                                            }
                                                        />
                                                        <div>
                                                            {(m.to ?? []).join(", ")}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {m.subject}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {isoDateStringToHumanReadable(
                                                        m.createdAt,
                                                    )}
                                                </td>{" "}
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {humanReadableMessageType(
                                                        m.messageType,
                                                    )}
                                                </td>
                                                {showCommunity ? (
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {m.intakeCommunity ? (
                                                            <Link
                                                                className={
                                                                    defaultLinkStyle
                                                                }
                                                                to={
                                                                    m.intakeCommunity
                                                                        .appLink
                                                                }>
                                                                {m.intakeCommunity.name ??
                                                                    "(no name)"}
                                                            </Link>
                                                        ) : null}
                                                    </td>
                                                ) : null}
                                                {showClient ? (
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {m.intakeClient ? (
                                                            <ClientPersonaLink
                                                                client={m.intakeClient}
                                                            />
                                                        ) : null}
                                                    </td>
                                                ) : null}
                                                {enhancedMode ? (
                                                    <>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {openedEvents.length}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {m.clickedLinkEvents?.reduce(
                                                                (acc, e) =>
                                                                    acc +
                                                                    (e.eventsV2?.length ||
                                                                        0),
                                                                0,
                                                            ) || 0}
                                                        </td>
                                                    </>
                                                ) : null}
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {attachments.map((a, i) => (
                                                        <div key={i}>{a}</div>
                                                    ))}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <a
                                                        href="#"
                                                        className="text-brand-600 hover:text-brand-900"
                                                        onClick={() => setViewing(m.id)}>
                                                        View
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
