import { Bars3Icon } from "@heroicons/react/24/outline";
import {
    CustomLayoutSectionParentKey,
    IDetails,
    IQuestion,
    LayoutSectionId,
    LayoutSectionLegacyPerspective,
    LayoutSectionParentKey,
    QuestionCategories,
    SectionRenderBehaviorType,
    isCustomSection,
} from "@sp-crm/core";
import { invalidateLayoutQueriesExcept } from "components/layout/layout-query-helpers";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import {
    BridgeEntityResult,
    GetLayoutContainerByKeyQuery,
    PatchLayoutSectionInput,
    useCreateLayoutSectionMutation,
    useDeleteLayoutSectionMutation,
    useGetLayoutContainerByKeyQuery,
    usePatchLayoutSectionMutation,
    usePatchLayoutSectionsMutation,
} from "../../generated/graphql";
import {
    calculateNewOrder,
    useReorderableListDrag,
    useReorderableListDrop,
} from "../../util/dnd";
import { QueryRenderer } from "../clients/show-client/community-comparison/query-renderer";
import { LayoutSectionResult } from "../layout/layout-items";
import { LayoutSectionHeader } from "../layout/layout-section-header";
import { LayoutSectionAgencySettings } from "./layout-section-agency-settings";
import { LayoutSectionItemSettings } from "./layout-section-item-settings";

interface LayoutSettingsProps {
    questions: IQuestion[];
    sectionParentKey: LayoutSectionParentKey | CustomLayoutSectionParentKey;
    label: string;
    entityMetadataName: BridgeEntityResult["name"];
    includeStandardFields: boolean;
    allowEditVisibility: boolean;
    allowEditOrder: boolean;
    mode: "alwaysEditing" | "toggleEdit";
    allowEditFields: boolean;
    showContainerTitle: boolean;
    enableNaryFormat: boolean;
    allowCreateSections: boolean;
    allowEditIcon: "always" | "never" | "custom-only";
    allowEditTitle: "always" | "never" | "custom-only";
    allowEditItemTitles: boolean;
}

interface LayoutSectionListItemProps {
    questions: IQuestion[];
    section: LayoutSectionResult;
    index: number;
    sectionParentKey: LayoutSectionParentKey | CustomLayoutSectionParentKey;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    commitOrder: () => void;
    revertOrder: () => void;
    patchSection: (params: PatchLayoutSectionInput) => void;
    entityMetadataName: BridgeEntityResult["name"];
    includeStandardFields: boolean;
    allowEditVisibility: boolean;
    allowEditOrder: boolean;
    allowEditFields: boolean;
    mode: "alwaysEditing" | "toggleEdit";
    enableNaryFormat: boolean;
    deleteSection?: (id: LayoutSectionId) => void;
    allowEditIcon: "always" | "never" | "custom-only";
    allowEditTitle: "always" | "never" | "custom-only";
    allowEditItemTitles: boolean;
}

const layoutSectionQuestionFilter = (section: LayoutSectionResult): boolean => {
    if (
        section.renderBehavior !== SectionRenderBehaviorType.LayoutItems &&
        section.renderBehavior !== SectionRenderBehaviorType.CustomWithLayoutItems
    ) {
        return false;
    }

    if (section.includeRelations?.length > 0) {
        return false;
    }

    return true;
};

const layoutSectionCategoryFilter = (
    category: IDetails,
    section: LayoutSectionResult,
): boolean => {
    if (
        section.renderBehavior !== SectionRenderBehaviorType.LayoutItems &&
        section.renderBehavior !== SectionRenderBehaviorType.CustomWithLayoutItems
    ) {
        return false;
    }

    if (section.includeRelations?.length > 0) {
        return false;
    }

    if (section.questionCategory) {
        const categoryAsNumber =
            QuestionCategories[
                section.questionCategory as keyof typeof QuestionCategories
            ];

        if (typeof categoryAsNumber !== "number") {
            return false;
        }

        return category.category === categoryAsNumber;
    }

    return true;
};

const layoutSectionCategoryTitle = (
    category: IDetails,
    section: LayoutSectionResult,
): string => {
    if (section.legacyPerspective === LayoutSectionLegacyPerspective.Community) {
        return category.communityHeading;
    }

    if (section.legacyPerspective === LayoutSectionLegacyPerspective.Client) {
        return category.clientHeading;
    }

    return section.title;
};

const layoutSectionQuestionTitle = (question: IQuestion): string => {
    return question.title;
};

const LayoutSectionListItem: React.FC<LayoutSectionListItemProps> = props => {
    const {
        section,
        sectionParentKey,
        index,
        commitOrder,
        revertOrder,
        reorder,
        questions,
        entityMetadataName,
        includeStandardFields,
        allowEditVisibility,
        allowEditOrder,
        mode,
        allowEditFields,
        enableNaryFormat,
        deleteSection,
        patchSection,
        allowEditItemTitles,
    } = props;
    const ref = React.useRef<HTMLLIElement>(null);
    const type = `layout-section-${sectionParentKey}`;
    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        commitOrder,
        revertOrder,
        id: section.id,
    });
    // eslint-disable-next-line no-empty-pattern
    const [{}, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });
    drop(ref);

    const onVisibleClicked = useCallback(() => {
        patchSection({ id: section.id, visible: !section.visible });
    }, [section, patchSection]);

    const questionFilter = useCallback(
        (_question: IQuestion) => {
            return layoutSectionQuestionFilter(section);
        },
        [section],
    );

    const categoryFilter = useCallback(
        (detail: IDetails) => {
            return layoutSectionCategoryFilter(detail, section);
        },
        [section],
    );

    const categoryTitle = useCallback(
        (detail: IDetails) => {
            return layoutSectionCategoryTitle(detail, section);
        },
        [section],
    );

    const questionTitle = useCallback((question: IQuestion) => {
        return layoutSectionQuestionTitle(question);
    }, []);

    const handleDelete = useCallback(() => {
        if (deleteSection) {
            deleteSection(section.id);
        }
    }, [deleteSection, section]);

    const handleUpdateIcon = useCallback(
        (icon: string) => {
            patchSection({ id: section.id, icon });
        },
        [patchSection, section],
    );

    const handleUpdateTitle = useCallback(
        (title: string) => {
            patchSection({ id: section.id, title });
        },
        [patchSection, section],
    );

    const isCustom = isCustomSection(section.sectionKey);
    const allowEditIcon =
        props.allowEditIcon === "always" ||
        (isCustom && props.allowEditIcon === "custom-only");
    const allowEditTitle =
        props.allowEditTitle === "always" ||
        (isCustom && props.allowEditTitle === "custom-only");

    return (
        <li className={`py-1 ${section.visible ? "" : "opacity-60"}`} ref={ref}>
            <div ref={preview} className={`flex ${isDragging ? "opacity-0" : ""}`}>
                {allowEditOrder ? (
                    <div className="cursor-move px-1 py-3" ref={drag}>
                        <Bars3Icon className="w-6 h-6 text-gray-500" />
                    </div>
                ) : null}
                <div className="border bg-white flex-grow pt-3 pb-1 px-2">
                    <LayoutSectionHeader
                        layoutSection={section}
                        visible={section.visible}
                        onVisibleClicked={onVisibleClicked}
                        allowEditVisibility={allowEditVisibility}
                        onDelete={deleteSection ? handleDelete : undefined}
                        onUpdateIcon={allowEditIcon ? handleUpdateIcon : undefined}
                        onUpdateTitle={allowEditTitle ? handleUpdateTitle : undefined}
                    />
                    <LayoutSectionAgencySettings layoutSection={section} />
                    {section.renderBehavior === SectionRenderBehaviorType.LayoutItems ||
                    section.renderBehavior ===
                        SectionRenderBehaviorType.CustomWithLayoutItems ? (
                        <div className="pl-1 pr-2 pb-2">
                            <LayoutSectionItemSettings
                                questions={questions}
                                questionFilter={questionFilter}
                                categoryFilter={categoryFilter}
                                categoryTitle={categoryTitle}
                                questionTitle={questionTitle}
                                relations={section.includeRelations || []}
                                layoutSectionId={section.id}
                                mode={mode}
                                enableShowWhenEmpty={false}
                                enableNaryFormat={enableNaryFormat}
                                entityMetadataName={entityMetadataName}
                                includeStandardFields={includeStandardFields}
                                allowEditFields={allowEditFields}
                                allowEditItemTitles={allowEditItemTitles}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </li>
    );
};

export const LayoutSettings: React.FC<LayoutSettingsProps> = props => {
    const {
        label,
        sectionParentKey,
        questions,
        entityMetadataName,
        includeStandardFields,
        allowEditVisibility,
        allowEditOrder,
        mode,
        allowEditFields,
        showContainerTitle,
        enableNaryFormat,
        allowCreateSections,
        allowEditIcon,
        allowEditTitle,
        allowEditItemTitles,
    } = props;

    const client = useQueryClient();
    const layoutContainerQuery = useGetLayoutContainerByKeyQuery({
        key: sectionParentKey,
    });
    const invalidateQueries = () => {
        invalidateLayoutQueriesExcept(client, ["getLayoutContainerByKey"]);
    };

    const patchLayoutSection = usePatchLayoutSectionMutation({
        onSuccess: invalidateQueries,
    });
    const patchLayoutSections = usePatchLayoutSectionsMutation({
        onSuccess: invalidateQueries,
    });
    const createLayoutSection = useCreateLayoutSectionMutation({
        onSuccess: invalidateQueries,
    });
    const deleteLayoutSection = useDeleteLayoutSectionMutation({
        onSuccess: invalidateQueries,
    });

    const reorder = useCallback(
        (draggingIndex: number, targetIndex: number) => {
            const data = client.getQueryData<GetLayoutContainerByKeyQuery>([
                "getLayoutContainerByKey",
                { key: sectionParentKey },
            ]);

            if (!data) {
                return;
            }

            const payload = produce(data, draft => {
                const newOrder = calculateNewOrder(
                    draft.getLayoutContainerByKey.layoutSections,
                    draggingIndex,
                    targetIndex,
                );

                const current =
                    draft.getLayoutContainerByKey.layoutSections[draggingIndex];
                const currentActualElement =
                    draft.getLayoutContainerByKey.layoutSections.find(
                        i => i.id === current.id,
                    );
                currentActualElement.order = newOrder;
                draft.getLayoutContainerByKey.layoutSections.sort(
                    (a, b) => a.order - b.order,
                );
            });

            client.setQueryData(
                ["getLayoutContainerByKey", { key: sectionParentKey }],
                payload,
            );
        },
        [client, sectionParentKey],
    );

    const commitOrder = useCallback(async () => {
        const data = client.getQueryData<GetLayoutContainerByKeyQuery>([
            "getLayoutContainerByKey",
            { key: sectionParentKey },
        ]);

        if (!data) {
            return;
        }

        const patches = data.getLayoutContainerByKey.layoutSections
            .sort((a, b) => a.order - b.order)
            .map((x, index) => {
                return {
                    id: x.id,
                    order: index,
                    visible: x.visible,
                };
            });

        await patchLayoutSections.mutateAsync({
            params: {
                layoutSections: patches,
            },
        });
        layoutContainerQuery.refetch();
    }, [client, layoutContainerQuery, sectionParentKey, patchLayoutSections]);
    const revertOrder = useCallback(() => {
        layoutContainerQuery.refetch();
    }, [layoutContainerQuery]);

    const patchSection = useCallback(
        async (params: PatchLayoutSectionInput) => {
            const newSection = await patchLayoutSection.mutateAsync({
                params,
            });

            const data = client.getQueryData<GetLayoutContainerByKeyQuery>([
                "getLayoutContainerByKey",
                { key: sectionParentKey },
            ]);

            if (!data) {
                return;
            }

            const newData = produce(data, draft => {
                const section = draft.getLayoutContainerByKey.layoutSections.find(
                    i => i.id === params.id,
                );

                if (!section) {
                    return;
                }

                section.visible = newSection.patchLayoutSection.visible;
                section.title = newSection.patchLayoutSection.title;
                section.icon = newSection.patchLayoutSection.icon;
            });

            client.setQueryData(
                ["getLayoutContainerByKey", { key: sectionParentKey }],
                newData,
            );
        },
        [patchLayoutSection, client, sectionParentKey],
    );

    const handleCreateSection = useCallback(
        async (container: GetLayoutContainerByKeyQuery["getLayoutContainerByKey"]) => {
            const newSection = await createLayoutSection.mutateAsync({
                params: {
                    layoutContainerId: container.id,
                    entityType: entityMetadataName,
                },
            });

            const data = client.getQueryData<GetLayoutContainerByKeyQuery>([
                "getLayoutContainerByKey",
                { key: sectionParentKey },
            ]);

            if (!data) {
                return;
            }

            const newData = produce(data, draft => {
                draft.getLayoutContainerByKey.layoutSections.push(
                    newSection.createLayoutSection,
                );
            });

            client.setQueryData(
                ["getLayoutContainerByKey", { key: sectionParentKey }],
                newData,
            );
        },
        [createLayoutSection, client, sectionParentKey, entityMetadataName],
    );

    const handleDelete = useCallback(
        async (id: LayoutSectionId) => {
            await deleteLayoutSection.mutateAsync({ id });

            const data = client.getQueryData<GetLayoutContainerByKeyQuery>([
                "getLayoutContainerByKey",
                { key: sectionParentKey },
            ]);

            if (!data) {
                return;
            }

            const newData = produce(data, draft => {
                draft.getLayoutContainerByKey.layoutSections =
                    data.getLayoutContainerByKey.layoutSections.filter(i => i.id !== id);
            });

            client.setQueryData(
                ["getLayoutContainerByKey", { key: sectionParentKey }],
                newData,
            );
        },
        [deleteLayoutSection, client, sectionParentKey],
    );

    return (
        <>
            <div>{label}</div>
            <QueryRenderer
                name={`LayoutSettings:${sectionParentKey}`}
                query={layoutContainerQuery}>
                {data => {
                    return (
                        <div className="space-y-2">
                            <p>
                                {showContainerTitle
                                    ? data.getLayoutContainerByKey.title
                                    : null}
                            </p>
                            <ul>
                                {data.getLayoutContainerByKey.layoutSections.map(
                                    (section, index) => {
                                        const isCustom = isCustomSection(
                                            section.sectionKey,
                                        );
                                        return (
                                            <LayoutSectionListItem
                                                key={section.id}
                                                section={section}
                                                index={index}
                                                sectionParentKey={sectionParentKey}
                                                reorder={reorder}
                                                commitOrder={commitOrder}
                                                revertOrder={revertOrder}
                                                questions={questions}
                                                patchSection={patchSection}
                                                entityMetadataName={entityMetadataName}
                                                includeStandardFields={
                                                    includeStandardFields
                                                }
                                                allowEditVisibility={allowEditVisibility}
                                                allowEditOrder={allowEditOrder}
                                                mode={mode}
                                                allowEditFields={allowEditFields}
                                                enableNaryFormat={enableNaryFormat}
                                                deleteSection={
                                                    allowCreateSections && isCustom
                                                        ? handleDelete
                                                        : undefined
                                                }
                                                allowEditIcon={allowEditIcon}
                                                allowEditTitle={allowEditTitle}
                                                allowEditItemTitles={allowEditItemTitles}
                                            />
                                        );
                                    },
                                )}
                            </ul>
                            {allowCreateSections ? (
                                <div className="ml-8">
                                    <SecondaryButton
                                        onClick={e => {
                                            e.preventDefault();
                                            handleCreateSection(
                                                data.getLayoutContainerByKey,
                                            );
                                        }}>
                                        <span className="text-sm">Add section</span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                        </div>
                    );
                }}
            </QueryRenderer>
        </>
    );
};
