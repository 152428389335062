import { ActionType, RegionId, User } from "@sp-crm/core";
import { isFeatureEnabled } from "components/feature";
import { RegionToggle } from "components/region-toggle";
import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { NavLink } from "react-router-dom";
import { ApplicationState } from "store/state";
import { allowedRegionsForAction, isAllowed } from "../../util/permissions";
import { AddClientIcon } from "../clients/add-client-button/add-client-button";
import { CSVDownload } from "../csv-download";
import { HeaderItem } from "./header";
import { ReminderIcon } from "./reminder-icon";
import { UpdatesIcon } from "./updates";
import { UserMenu } from "./user-menu";
import { WhiteLabelRestricted } from "./white-label-restricted";

interface TopMenuBaseProps {
    selectedItem: HeaderItem;
    navigate: (url: string) => void;
    currentUser: User;
    logout: () => void;
}

interface TopMenuBasePropsFromStateAddon {
    reportsMode: "disabled" | "full-access" | "custom-only";
    csvDownloadEnabled: boolean;
    editAgencySettingsAllowed: boolean;
    regionId: RegionId;
    regionName: string;
    canAccessMultipleRegions: boolean;
    clientDisclosuresEnabled: boolean;
    showReferrals: boolean;
}

type TopMenuPropsFromState = TopMenuBasePropsFromStateAddon & TopMenuBaseProps;

type TopMenuProps = TopMenuPropsFromState;

interface TopMenuState {
    userMenuOpen: boolean;
    csvExportPanelOpen: boolean;
}

class TopMenu extends React.Component<TopMenuProps, TopMenuState> {
    constructor(props: TopMenuProps) {
        super(props);
        this.state = {
            userMenuOpen: false,
            csvExportPanelOpen: false,
        };
        this.isDashboardActive = this.isDashboardActive.bind(this);
        this.isClientsActive = this.isClientsActive.bind(this);
        this.showCsvInterface = this.showCsvInterface.bind(this);
    }

    private showCsvInterface() {
        this.setState({ csvExportPanelOpen: true });
    }

    private isDashboardActive(
        matchArg: match<unknown>,
        location: Location<unknown>,
    ): boolean {
        if (matchArg && matchArg.isExact) {
            return true;
        }
        if (location && location.pathname === "/") return true;
        if (location && location.pathname === "/pipeline") return true;
        if (location && location.pathname === "/timeline") return true;
        return false;
    }

    private isClientsActive(
        matchArg: match<unknown>,
        location: Location<unknown>,
    ): boolean {
        if (matchArg && matchArg.isExact) {
            return true;
        }
        if (location && location.pathname === "/") return true;
        if (location && location.pathname === "/pipeline") return true;
        if (location && location.pathname === "/timeline") return true;
        if (location && location.pathname && location.pathname.startsWith("/clients"))
            return true;
        return false;
    }

    private reportsLink(): JSX.Element | null {
        const { reportsMode } = this.props;
        if (reportsMode === "disabled") {
            return null;
        }
        if (reportsMode === "custom-only") {
            return (
                <li>
                    <NavLink
                        activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                        className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                        to="/reports/show/custom">
                        Reports
                    </NavLink>
                </li>
            );
        }
        if (reportsMode === "full-access") {
            return (
                <li>
                    <NavLink
                        activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                        className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                        to="/reports">
                        Reports
                    </NavLink>
                </li>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="top-menu">
                <ul className="ml-4 main-menu flex space-x-4 text-xl items-end">
                    <li>
                        <NavLink
                            isActive={this.isClientsActive}
                            activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                            className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                            to="/pipeline">
                            Clients
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                            className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                            to="/communities">
                            Communities
                        </NavLink>
                    </li>
                    {this.props.showReferrals ? (
                        <li>
                            <NavLink
                                activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                                className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                                to="/references">
                                Referrals
                            </NavLink>
                        </li>
                    ) : null}
                    <li>
                        <NavLink
                            activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                            className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                            to="/tasks">
                            Tasks
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                            className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                            to="/files">
                            Files
                        </NavLink>
                    </li>
                    {this.reportsLink()}
                </ul>
                <div className="region-toggle-wrapper">
                    <RegionToggle />
                </div>
                <div className="csv-export-panel">
                    {this.state.csvExportPanelOpen && (
                        <CSVDownload
                            regionName={this.props.regionName}
                            regionId={this.props.regionId}
                            canAccessMultipleRegions={this.props.canAccessMultipleRegions}
                            clientDisclosuresEnabled={this.props.clientDisclosuresEnabled}
                            onDismiss={() =>
                                this.setState({
                                    ...this.state,
                                    csvExportPanelOpen: false,
                                })
                            }
                        />
                    )}
                </div>
                <div className="flex items-center space-x-1">
                    <AddClientIcon />
                    <ReminderIcon />
                    <WhiteLabelRestricted>
                        <UpdatesIcon />
                    </WhiteLabelRestricted>
                    <UserMenu
                        showCsvInterface={this.showCsvInterface}
                        currentUser={this.props.currentUser}
                        editAgencySettingsAllowed={this.props.editAgencySettingsAllowed}
                        navigate={this.props.navigate}
                        logout={this.props.logout}
                        csvDownloadEnabled={this.props.csvDownloadEnabled}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: TopMenuBaseProps,
): TopMenuPropsFromState {
    const region = state.region.regions.find(r => r.key === state.region.selectedRegion);
    const regionId = region ? region.id : undefined;
    const regionName = region ? region.name : "";
    const canAccessMultipleRegions =
        allowedRegionsForAction(ActionType.ExportCsv, state.permissions).length > 1;
    return {
        ...ownProps,
        reportsMode: isAllowed(ActionType.ViewReports, state.permissions, state.region)
            ? "full-access"
            : "custom-only",

        csvDownloadEnabled: isAllowed(
            ActionType.ExportCsv,
            state.permissions,
            state.region,
        ),
        editAgencySettingsAllowed: isAllowed(
            ActionType.UpdateTenant,
            state.permissions,
            state.region,
        ),
        regionId,
        regionName,
        canAccessMultipleRegions,
        clientDisclosuresEnabled: isFeatureEnabled(state, "clientDisclosures"),
        showReferrals: isAllowed(
            ActionType.ViewReference,
            state.permissions,
            state.region,
        ),
    };
}

const component = connect(mapStateToProps)(TopMenu);
export { component as TopMenu };
