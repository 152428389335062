import { LinkIcon } from "@heroicons/react/24/outline";
import {
    formatEntityValue,
    getInteraction,
    getQuestionFieldFromSelect,
    getRawValue,
    InteractionType,
    QuestionType,
    renderAnswer,
} from "@sp-crm/core";
import {
    FIELD_PICKER_TYPE_SEPARATOR,
    getColumnTitle,
    getQuestionsForEntityType,
} from "components/reports/show/custom-reports/custom-reports-helpers";
import { CrmTablePagination } from "components/table/pagination";
import { ErrorMessage } from "components/ui/error-message";
import { SortDown, SortUp } from "components/ui/icon";
import { defaultLinkStyle } from "components/ui/link";
import {
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchRequestSort,
    AdvancedSearchSelectInput,
    Answer,
    BridgeEntityResult,
} from "generated/graphql";
import { produce } from "immer";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Link } from "react-router-dom";
import { useProductName } from "store/selectors/branding";
import { useRegions, useUsers } from "store/selectors/hooks";
import { isUrl, sanitizeUri } from "util/uri";
import {
    DynamicAdvancedSearchQuery,
    fieldPathToMetadata,
    useAdvancedSearchQuery,
} from "./advanced-search-request";
import { AdvancedSearchSummary } from "./advanced-search-summary";
import { AdvancedSearchTable } from "./advanced-search-table";

export interface RenderRowActionParams {
    record: Record<string, unknown>;
    refetch?: () => void;
}

interface AdvancedSearchResultsProps {
    request: AdvancedSearchRequest;
    onChangeRequest: Dispatch<SetStateAction<AdvancedSearchRequest>>;
    entityMetadataList: BridgeEntityResult[];
    renderRowAction?: (params: RenderRowActionParams) => JSX.Element;
    includeIconLinks?: boolean;
    includeSummaries?: boolean;
}

const generateLink = ({ record }: RenderRowActionParams) => {
    if (record.appLink) {
        return (
            <Link className={defaultLinkStyle} to={record.appLink}>
                View
            </Link>
        );
    }
    return null;
};

const generateIconLink = ({ record }: RenderRowActionParams) => {
    if (record.appLink) {
        return (
            <Link className={defaultLinkStyle} to={record.appLink}>
                <LinkIcon className="w-4 h-4" />
            </Link>
        );
    }
    return null;
};

interface AdvancedSearchResultsColumnHeaderProps {
    request: AdvancedSearchRequest;
    select: AdvancedSearchSelectInput;
    entityType: AdvancedSearchEntityType;
    entityMetadataList: BridgeEntityResult[];
    onClick: (select: AdvancedSearchSelectInput) => void;
}

const AdvancedSearchResultsColumnHeader: React.FC<
    AdvancedSearchResultsColumnHeaderProps
> = props => {
    const { select, entityType, entityMetadataList, onClick, request } = props;
    const title = getColumnTitle(select, entityType, entityMetadataList);

    const handleHeaderClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onClick(select);
        },
        [onClick, select],
    );

    const showSort =
        (select.fieldName && request.sort.column === select.fieldName) ||
        (select.questionId && request.sort.questionId === select.questionId);

    return (
        <a href="#" onClick={handleHeaderClick}>
            <p className="text-gray-900 flex items-center space-x-2">
                <span>{title}</span>
                {showSort ? request.sort?.ascending ? <SortUp /> : <SortDown /> : null}
            </p>
        </a>
    );
};

export const AdvancedSearchResults: React.FC<AdvancedSearchResultsProps> = props => {
    const {
        request,
        onChangeRequest,
        entityMetadataList,
        renderRowAction,
        includeIconLinks,
        includeSummaries,
    } = props;
    const regions = useRegions();
    const { users } = useUsers();
    const productName = useProductName();

    const entityMetadata = entityMetadataList.find(e => e.name === request.entityType);

    const handleHeaderClick = useCallback(
        (select: AdvancedSearchSelectInput) => {
            const newRequest = produce(request, draft => {
                const oldSort = draft.sort;
                const newSort: AdvancedSearchRequestSort = {
                    questionId: null,
                    column: null,
                    ascending: true,
                };

                if (select.fieldName) {
                    newSort.column = select.fieldName;
                    if (oldSort.column === select.fieldName) {
                        newSort.ascending = !oldSort.ascending;
                    }
                } else if (select.questionId) {
                    newSort.questionId = select.questionId;
                    if (oldSort.questionId === select.questionId) {
                        newSort.ascending = !oldSort.ascending;
                    }
                }

                draft.sort = newSort;
            });

            onChangeRequest(newRequest);
        },
        [request, onChangeRequest],
    );

    const columns = useMemo(() => {
        const fieldColumns = request.select
            .map(s => {
                const header = (
                    <AdvancedSearchResultsColumnHeader
                        request={request}
                        select={s}
                        entityType={request.entityType}
                        entityMetadataList={entityMetadataList}
                        onClick={handleHeaderClick}
                    />
                );

                const questionField = getQuestionFieldFromSelect(
                    s,
                    entityMetadata,
                    entityMetadataList,
                );

                if (questionField) {
                    const questions = getQuestionsForEntityType(
                        questionField.entityMetadata.name,
                        entityMetadataList,
                    );

                    const matchingQuestion = questions.find(
                        q => q.id === questionField.questionId,
                    );

                    if (!matchingQuestion) {
                        return null;
                    }

                    return {
                        key: `question${FIELD_PICKER_TYPE_SEPARATOR}${questionField.questionId}`,
                        header,
                        renderCell: ({ record }: { record: Record<string, unknown> }) => {
                            let matchingAnswer: Answer = null;
                            let answersEntity = record;
                            if (questionField.accessor !== ".") {
                                answersEntity = getRawValue(
                                    record,
                                    questionField.accessor,
                                ).getOrElse({}) as Record<string, unknown>;
                            }

                            if (Array.isArray(answersEntity["answers"])) {
                                matchingAnswer = (answersEntity["answers"] || []).find(
                                    a => a.questionId === questionField.questionId,
                                );
                            }

                            if (matchingAnswer) {
                                const renderedValue = renderAnswer(
                                    matchingQuestion,
                                    matchingAnswer,
                                    users,
                                );

                                if (
                                    renderedValue &&
                                    matchingQuestion.questionType === QuestionType.text &&
                                    getInteraction(matchingQuestion) ===
                                        InteractionType.url &&
                                    isUrl(renderedValue)
                                ) {
                                    return (
                                        <a
                                            className="truncate"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={sanitizeUri(renderedValue)}>
                                            {renderedValue}
                                        </a>
                                    );
                                }
                                return <span>{renderedValue}</span>;
                            }

                            return null;
                        },
                    };
                } else if (s.fieldName) {
                    const matchingField = fieldPathToMetadata(
                        s.fieldName.split("."),
                        entityMetadata,
                        entityMetadataList,
                    );
                    return {
                        key: s.fieldName,
                        header,
                        renderCell: ({ record }: { record: Record<string, unknown> }) => {
                            return (
                                <span>
                                    {formatEntityValue(
                                        record,
                                        s.fieldName,
                                        matchingField,
                                        {
                                            regions,
                                            users,
                                            productName,
                                        },
                                    )}
                                </span>
                            );
                        },
                    };
                }
                return null;
            })
            .filter(c => !!c);

        return includeIconLinks
            ? [
                  {
                      key: "__view__",
                      header: "",
                      renderCell: generateIconLink,
                      width: 4,
                  },
                  ...fieldColumns,
              ]
            : fieldColumns;
    }, [
        request,
        entityMetadata,
        entityMetadataList,
        regions,
        users,
        handleHeaderClick,
        includeIconLinks,
        productName,
    ]);

    const query = useAdvancedSearchQuery(request, entityMetadataList);

    const changePageSize = useCallback(
        (pageSize: number) => {
            onChangeRequest(r => ({ ...r, pageSize }));
        },
        [onChangeRequest],
    );
    const changePage = useCallback(
        (page: number) => {
            onChangeRequest(r => ({ ...r, page }));
        },
        [onChangeRequest],
    );

    const [dataToRender, setDataToRender] = useState<DynamicAdvancedSearchQuery>(null);

    useEffect(() => {
        if (query.data?.advancedSearch) {
            setDataToRender(query.data);
        }
    }, [query.data?.advancedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDataToRender(query.data || null);
    }, [request.select]); // eslint-disable-line react-hooks/exhaustive-deps

    const summaries = useMemo(() => {
        if (!includeSummaries) {
            return [];
        }

        if (dataToRender?.advancedSearch?.summaries) {
            return dataToRender.advancedSearch.summaries;
        }

        return [];
    }, [dataToRender?.advancedSearch?.summaries, includeSummaries]);

    return (
        <div>
            {query.isError ? (
                <ErrorMessage component="AdvancedSearchResults">
                    <pre>{JSON.stringify(query.error, null, 2)}</pre>
                </ErrorMessage>
            ) : null}
            {summaries.length > 0 ? (
                <div className="pb-4">
                    <p className="text-xl mb-4">Summary</p>
                    <AdvancedSearchSummary
                        request={request}
                        summaries={summaries}
                        entityMetadata={entityMetadata}
                        onRequestChange={onChangeRequest}
                    />
                </div>
            ) : null}
            {summaries.length > 0 ? <p className="text-xl">All records</p> : null}
            <div className={`max-w-advanced-search`}>
                <div className="md:overflow-x-scroll">
                    <AdvancedSearchTable
                        data={dataToRender?.advancedSearch?.entities || []}
                        columns={columns}
                        special={props => {
                            const params = {
                                ...props,
                                refetch: () => query.refetch(),
                            };
                            if (renderRowAction) {
                                return renderRowAction(params);
                            }

                            return generateLink(params);
                        }}
                    />
                </div>
            </div>
            {dataToRender ? (
                <CrmTablePagination
                    pageIndex={dataToRender.advancedSearch.page}
                    count={dataToRender.advancedSearch.total}
                    pageSize={dataToRender.advancedSearch.pageSize}
                    pageCount={Math.ceil(
                        dataToRender.advancedSearch.total /
                            dataToRender.advancedSearch.pageSize,
                    )}
                    canNextPage={
                        dataToRender.advancedSearch.page <
                        Math.ceil(
                            dataToRender.advancedSearch.total /
                                dataToRender.advancedSearch.pageSize,
                        ) -
                            1
                    }
                    canPreviousPage={dataToRender.advancedSearch.page > 0}
                    nextPage={() => changePage(dataToRender.advancedSearch.page + 1)}
                    previousPage={() => changePage(dataToRender.advancedSearch.page - 1)}
                    gotoPage={changePage}
                    setPageSize={changePageSize}
                />
            ) : null}
        </div>
    );
};
