import { WorkflowExecutionId } from "@sp-crm/core";
import {
    AdvancedSearchResults,
    RenderRowActionParams,
} from "components/advanced-search/advanced-search-results";
import { DeleteButton } from "components/ui/action-button";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    FieldConditionOperator,
    useCancelWorkflowExecutionMutation,
    useGetEntitiesQuery,
    WorkflowExecutionResult,
} from "generated/graphql";
import React, { useCallback, useState } from "react";
import { stableQueryOptions } from "util/requests";

export const WorkflowExecutionList: React.FC<unknown> = () => {
    const [advancedSearchRequest, setAdvancedSearchRequest] =
        useState<AdvancedSearchRequest>({
            entityType: AdvancedSearchEntityType.WorkflowExecution,
            condition: {
                nodeType: AdvancedSearchConditionNodeType.And,
                children: [
                    {
                        nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                        operator: FieldConditionOperator.StringEquals,
                        fieldName: "result",
                        textValue: WorkflowExecutionResult.Pending,
                    },
                    {
                        nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                        operator: FieldConditionOperator.RelationMatches,
                        fieldName: "client",
                        children: [
                            {
                                nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                                operator: FieldConditionOperator.UserIsMe,
                                fieldName: "assignedUserId",
                            },
                        ],
                    },
                ],
            },
            select: [
                { fieldName: "workflow.name", title: "Workflow" },
                { fieldName: "client.name", title: "Client" },
                { fieldName: "client.bestContactEntity.name", title: "Best Contact" },
                { fieldName: "scheduledAt" },
            ],
            sort: { column: "scheduledAt", ascending: true },
        });

    const bridgeTypesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    const cancelMutation = useCancelWorkflowExecutionMutation();
    const handleDelete = useCallback(
        async (executionId: WorkflowExecutionId, refetch: () => void) => {
            await cancelMutation.mutateAsync({ workflowExecutionId: executionId });
            refetch();
        },
        [cancelMutation],
    );

    const renderDeleteButton = useCallback(
        (params: RenderRowActionParams) => {
            return (
                <DeleteButton
                    backgroundColor="bg-white"
                    onClick={() =>
                        handleDelete(
                            params.record.id as WorkflowExecutionId,
                            params.refetch,
                        )
                    }
                    confirm={{
                        title: "Remove",
                        message:
                            "Are you sure you want to stop this workflow from running?",
                        confirmLabel: "Yes, stop this workflow",
                        cancelLabel: "No, keep it running",
                    }}
                />
            );
        },
        [handleDelete],
    );

    if (!bridgeTypesQuery.data) {
        return null;
    }

    return (
        <AdvancedSearchResults
            request={advancedSearchRequest}
            onChangeRequest={setAdvancedSearchRequest}
            entityMetadataList={bridgeTypesQuery.data.getEntities}
            renderRowAction={renderDeleteButton}
            includeIconLinks={true}
        />
    );
};
