import { PromptId, QuestionId, QuestionType, UserId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { Icon } from "components/icon";
import { Checkbox } from "components/ui/checkbox";
import {
    AdvancedSearchCondition,
    AdvancedSearchConditionNodeType,
    BridgeEntityResult,
    FieldConditionOperator,
    GetLayoutSectionsQuery,
    useGetUsersForRegionQuery,
} from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

interface EntityFiltersProps {
    entityMetadata: BridgeEntityResult;
    layoutSections: GetLayoutSectionsQuery["getLayoutSections"]["layoutSections"];
    condition: AdvancedSearchCondition | null;
    onChange: (condition: AdvancedSearchCondition | null) => void;
}

const isPromptSelected = (
    condition: AdvancedSearchCondition | null,
    promptId: PromptId,
): boolean => {
    if (!condition) {
        return false;
    }

    if (condition.nodeType === AdvancedSearchConditionNodeType.FieldCondition) {
        return condition.promptId === promptId;
    }

    return !!condition.children?.some(c => isPromptSelected(c, promptId));
};

const isUserSelected = (
    condition: AdvancedSearchCondition | null,
    userId: string,
): boolean => {
    if (!condition) {
        return false;
    }

    if (condition.nodeType === AdvancedSearchConditionNodeType.FieldCondition) {
        return condition.textValue === userId;
    }

    return !!condition.children?.some(c => isUserSelected(c, userId));
};

const ensureCondition = (
    condition: AdvancedSearchCondition | null,
): AdvancedSearchCondition => {
    if (!condition) {
        return {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [],
        };
    }

    return condition;
};

const addPromptId = (
    condition: AdvancedSearchCondition | null,
    questionId: QuestionId,
    promptId: PromptId,
): AdvancedSearchCondition => {
    const result = ensureCondition(condition);

    return produce(result, draft => {
        let existingQuestion = draft.children?.find(
            c =>
                c.nodeType === AdvancedSearchConditionNodeType.Or &&
                c.children?.some(
                    c =>
                        c.nodeType === AdvancedSearchConditionNodeType.FieldCondition &&
                        c.questionId === questionId,
                ),
        );

        if (!existingQuestion) {
            draft.children = draft.children || [];
            draft.children.push({
                nodeType: AdvancedSearchConditionNodeType.Or,
                children: [],
            });
            existingQuestion = draft.children[draft.children.length - 1];
        }

        const existingPrompt = existingQuestion.children?.find(
            c =>
                c.nodeType === AdvancedSearchConditionNodeType.FieldCondition &&
                c.promptId === promptId,
        );

        if (!existingPrompt) {
            existingQuestion.children = existingQuestion.children || [];
            existingQuestion.children.push({
                nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                questionId,
                promptId,
                operator: FieldConditionOperator.NaryContains,
            });
        }
    });
};

const nullIfEmpty = (condition: AdvancedSearchCondition) => {
    if (
        !condition.children ||
        condition.children?.length === 0 ||
        condition.children?.every(c => !c.children || c.children?.length === 0)
    ) {
        return null;
    }

    return condition;
};

const removePromptId = (
    condition: AdvancedSearchCondition | null,
    questionId: QuestionId,
    promptId: PromptId,
): AdvancedSearchCondition => {
    const result = ensureCondition(condition);

    return nullIfEmpty(
        produce(result, draft => {
            const existingQuestion = draft.children?.find(
                c =>
                    c.nodeType === AdvancedSearchConditionNodeType.Or &&
                    c.children?.some(
                        c =>
                            c.nodeType ===
                                AdvancedSearchConditionNodeType.FieldCondition &&
                            c.questionId === questionId,
                    ),
            );

            if (existingQuestion) {
                existingQuestion.children = existingQuestion.children?.filter(
                    c => c.promptId !== promptId,
                );
            }
        }),
    );
};

const addUserId = (
    condition: AdvancedSearchCondition | null,
    questionId: QuestionId,
    userId: UserId,
): AdvancedSearchCondition => {
    const result = ensureCondition(condition);

    return produce(result, draft => {
        let existingQuestion = draft.children?.find(
            c =>
                c.nodeType === AdvancedSearchConditionNodeType.Or &&
                c.children?.some(
                    c =>
                        c.nodeType === AdvancedSearchConditionNodeType.FieldCondition &&
                        c.questionId === questionId,
                ),
        );

        if (!existingQuestion) {
            draft.children = draft.children || [];
            draft.children.push({
                nodeType: AdvancedSearchConditionNodeType.Or,
                children: [],
            });
            existingQuestion = draft.children[draft.children.length - 1];
        }

        const existingUser = existingQuestion.children?.find(
            c =>
                c.nodeType === AdvancedSearchConditionNodeType.FieldCondition &&
                c.textValue === userId,
        );

        if (!existingUser) {
            existingQuestion.children = existingQuestion.children || [];
            existingQuestion.children.push({
                nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                questionId,
                textValue: userId,
                operator: FieldConditionOperator.IdEquals,
            });
        }
    });
};

const removeUserId = (
    condition: AdvancedSearchCondition | null,
    questionId: QuestionId,
    userId: UserId,
): AdvancedSearchCondition => {
    const result = ensureCondition(condition);

    return nullIfEmpty(
        produce(result, draft => {
            const existingQuestion = draft.children?.find(
                c =>
                    c.nodeType === AdvancedSearchConditionNodeType.Or &&
                    c.children?.some(
                        c =>
                            c.nodeType ===
                                AdvancedSearchConditionNodeType.FieldCondition &&
                            c.questionId === questionId,
                    ),
            );

            if (existingQuestion) {
                existingQuestion.children = existingQuestion.children?.filter(
                    c => c.textValue !== userId,
                );
            }
        }),
    );
};

export const EntityFilters: React.FC<EntityFiltersProps> = props => {
    const { entityMetadata, layoutSections, condition, onChange } = props;

    return (
        <div>
            {layoutSections.map(layoutSection => {
                return layoutSection.layoutItems.length > 0 ? (
                    <div key={layoutSection.id}>
                        <div className="flex items-center space-x-2">
                            <div className="w-5">
                                <Icon name={layoutSection.icon} />
                            </div>
                            <p className="font-bold">{layoutSection.title}</p>
                        </div>
                        <ul className="space-y-2">
                            {layoutSection.layoutItems.map(layoutItem => (
                                <li key={layoutItem.id}>
                                    <LayoutItemFilter
                                        entityMetadata={entityMetadata}
                                        layoutItem={layoutItem}
                                        condition={condition}
                                        onChange={onChange}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null;
            })}
        </div>
    );
};

interface LayoutItemFilterProps {
    entityMetadata: BridgeEntityResult;
    layoutItem: GetLayoutSectionsQuery["getLayoutSections"]["layoutSections"][0]["layoutItems"][0];
    condition: AdvancedSearchCondition | null;
    onChange: (condition: AdvancedSearchCondition | null) => void;
}

const LayoutItemFilter: React.FC<LayoutItemFilterProps> = props => {
    const { layoutItem, entityMetadata, condition, onChange } = props;

    if (!layoutItem.questionId) {
        return null;
    }

    const question = entityMetadata.questions.find(q => q.id === layoutItem.questionId);

    if (!question) {
        return null;
    }

    return (
        <div>
            <LayoutItemQuestionFilter
                question={question}
                condition={condition}
                onChange={onChange}
            />
        </div>
    );
};

interface LayoutItemFilterQuestionBodyProps {
    question: BridgeEntityResult["questions"][0];
    condition: AdvancedSearchCondition | null;
    onChange: (condition: AdvancedSearchCondition | null) => void;
}

const LayoutItemQuestionFilter: React.FC<LayoutItemFilterQuestionBodyProps> = props => {
    const { question, condition, onChange } = props;

    switch (question.questionType) {
        case QuestionType[QuestionType.nary]: {
            return (
                <NaryQuestionFilter
                    question={question}
                    condition={condition}
                    onChange={onChange}
                />
            );
        }
        case QuestionType[QuestionType.user]: {
            return (
                <UserFilter
                    question={question}
                    condition={condition}
                    onChange={onChange}
                />
            );
        }
        default: {
            return null;
        }
    }
};

const NaryQuestionFilter: React.FC<LayoutItemFilterQuestionBodyProps> = props => {
    const { question, condition, onChange } = props;

    if (question.prompts?.length > 0) {
        return (
            <div>
                <p className="font-bold">{question.title}</p>
                <ul>
                    {question.prompts.map(prompt => (
                        <li key={prompt.id}>
                            <Checkbox
                                label={prompt.title}
                                checked={isPromptSelected(condition, prompt.id)}
                                onChange={e => {
                                    if (e.target.checked) {
                                        onChange(
                                            addPromptId(
                                                condition,
                                                question.id,
                                                prompt.id,
                                            ),
                                        );
                                    } else {
                                        onChange(
                                            removePromptId(
                                                condition,
                                                question.id,
                                                prompt.id,
                                            ),
                                        );
                                    }
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return null;
};

const UserFilter: React.FC<LayoutItemFilterQuestionBodyProps> = props => {
    const { question, condition, onChange } = props;
    const regionId = useRegionId();
    const users = useGetUsersForRegionQuery(
        { regionId },
        { ...stableQueryOptions(), enabled: !!regionId },
    );

    return (
        <div>
            <p className="font-bold">{question.title}</p>
            <QueryRenderer query={users} name="useGetUsersForRegionQuery">
                {data => (
                    <ul>
                        {Object.values(data.getUsersForRegion)
                            .sort((a, b) =>
                                a.preferredName
                                    .toLocaleLowerCase()
                                    .localeCompare(b.preferredName.toLocaleLowerCase()),
                            )
                            .map(user => (
                                <li key={user.id}>
                                    <Checkbox
                                        label={user.preferredName}
                                        checked={isUserSelected(condition, user.id)}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                onChange(
                                                    addUserId(
                                                        condition,
                                                        question.id,
                                                        user.id,
                                                    ),
                                                );
                                            } else {
                                                onChange(
                                                    removeUserId(
                                                        condition,
                                                        question.id,
                                                        user.id,
                                                    ),
                                                );
                                            }
                                        }}
                                    />
                                </li>
                            ))}
                    </ul>
                )}
            </QueryRenderer>
        </div>
    );
};
