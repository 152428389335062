import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    hostedFormClientContainerKey,
    QuestionType,
    RegionId,
    TenantRegion,
    UserId,
} from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { CopyableLink } from "components/shared/copyable-link";
import { Radio } from "components/shared/radio";
import { UserSelect } from "components/shared/user-select";
import { AutosavingInput } from "components/ui/autosaving-input";
import { InlineBanner } from "components/ui/inline-banner";
import { MultiUserSelect } from "components/ui/multi-user-select";
import { PrimaryButton } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    GetHostedFormsQuery,
    HostedFormStyle,
    useCreateHostedFormMutation,
    useGetHostedFormsQuery,
    usePatchHostedFormMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { useProductName } from "store/selectors/branding";
import { useBridgeQuestions } from "store/selectors/bridge";
import {
    useFeature,
    useIsMultiRegionUser,
    useRegions,
    useUsersForRegion,
} from "store/selectors/hooks";
import { LayoutSettings } from "../layout-settings";

export const AgencySettingsHostedForm: React.FC<unknown> = () => {
    const [isWorking, setIsWorking] = useState(false);
    const hostedFormQuery = useGetHostedFormsQuery();
    const createHostedFormMutation = useCreateHostedFormMutation();
    const createHostedForm = useCallback(async () => {
        setIsWorking(true);
        await createHostedFormMutation.mutateAsync({});
        hostedFormQuery.refetch();
        setIsWorking(false);
    }, [hostedFormQuery, createHostedFormMutation, setIsWorking]);

    const multiRegion = useIsMultiRegionUser();
    const hostedIntakeFormUnlimited = useFeature("hostedIntakeFormUnlimited");
    const productName = useProductName();

    return (
        <ContentFull>
            <ContentHeader>Hosted Intake Form</ContentHeader>
            <div className="space-y-4">
                <p>
                    Add new client records to {productName} by completing an intake form.
                    Share the direct link or embed the form on your website.{" "}
                    {multiRegion ? `Each region has its own link.` : ""}
                </p>
                <QueryRenderer query={hostedFormQuery} name="AgencySettingsHostedForm">
                    {data => (
                        <>
                            {data.getHostedForms.length === 0 ? (
                                <PrimaryButton
                                    disabled={isWorking}
                                    onClick={createHostedForm}>
                                    {isWorking ? (
                                        <Spinner />
                                    ) : (
                                        <span>Create intake form</span>
                                    )}
                                </PrimaryButton>
                            ) : (
                                <div className="space-y-8 pt-8">
                                    {data.getHostedForms
                                        .sort((a, b) =>
                                            a.dateAdded.localeCompare(b.dateAdded),
                                        )
                                        .map(form => (
                                            <HostedForm
                                                key={form.id}
                                                form={form}
                                                refetch={hostedFormQuery.refetch}
                                                multiFormExperience={
                                                    data.getHostedForms.length > 1
                                                }
                                            />
                                        ))}
                                    {hostedIntakeFormUnlimited ? (
                                        <div>
                                            <PrimaryButton
                                                disabled={isWorking}
                                                onClick={createHostedForm}>
                                                {isWorking ? (
                                                    <Spinner />
                                                ) : (
                                                    <span>
                                                        Create another intake form
                                                    </span>
                                                )}
                                            </PrimaryButton>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </>
                    )}
                </QueryRenderer>
            </div>
        </ContentFull>
    );
};

interface HostedFormProps {
    form: GetHostedFormsQuery["getHostedForms"][0];
    refetch: () => void;
    /**
     * True if this hosted form is one of several. Should start collapsed with a useful header.
     */
    multiFormExperience: boolean;
}

export const HostedForm: React.FC<HostedFormProps> = props => {
    const { form, refetch, multiFormExperience } = props;
    const [visible, setVisible] = useState(!multiFormExperience);
    const toggleVisibility = useCallback(() => {
        setVisible(v => !v);
    }, [setVisible]);
    const clientQuestions = useBridgeQuestions(AdvancedSearchEntityType.Client);
    const patchHostedFormMutation = usePatchHostedFormMutation();
    const handleRegionChange = async (
        form: GetHostedFormsQuery["getHostedForms"][0],
        regionId: RegionId,
        userId: UserId,
        recipientUserIds: UserId[],
    ) => {
        const newRegions = produce(form.configuration.regions || [], draft => {
            const existingRegion = draft.find(a => a.regionId === regionId);
            if (existingRegion) {
                existingRegion.userId = userId;
                existingRegion.recipientUserIds = recipientUserIds;
            } else {
                draft.push({ regionId, userId, recipientUserIds });
            }
        });

        await patchHostedFormMutation.mutateAsync({
            params: {
                id: form.id,
                regions: newRegions,
            },
        });
        refetch();
    };
    const questions = useMemo(() => {
        return clientQuestions.filter(q => q.questionType !== QuestionType.user);
    }, [clientQuestions]);
    const updateName = useCallback(
        async (name: string) => {
            await patchHostedFormMutation.mutateAsync({
                params: {
                    id: form.id,
                    name,
                },
            });
            refetch();
        },
        [form, patchHostedFormMutation, refetch],
    );
    const updateStyle = useCallback(
        async (style: HostedFormStyle) => {
            await patchHostedFormMutation.mutateAsync({
                params: {
                    id: form.id,
                    style,
                },
            });
            refetch();
        },
        [form, patchHostedFormMutation, refetch],
    );
    return (
        <div>
            <h2 className="font-bold text-lg flex items-center space-x-2">
                <button onClick={toggleVisibility}>
                    <ChevronDownIcon
                        className={`w-6 h-6 transform transition duration-50 ${
                            visible ? "rotate-0" : "-rotate-90"
                        }`}
                    />
                </button>
                <div>{form.displayName}</div>
            </h2>
            <Transition
                as="div"
                show={visible}
                enter="transition-opacity duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <HostedFormConfiguration
                    form={form}
                    onRegionChange={(regionId, userId, recipientUserIds) =>
                        handleRegionChange(form, regionId, userId, recipientUserIds)
                    }
                    configureName={multiFormExperience}
                    handleNameChange={updateName}
                    handleStyleChange={updateStyle}
                />
                <h3 className="mt-8">Form Layout</h3>
                {!form.customizable ? (
                    <InlineBanner type="info">
                        This layout is customizable on a higher subscription plan. Contact
                        your administrator or <SupportEmailLink /> to upgrade to a higher
                        plan.
                    </InlineBanner>
                ) : null}
                <LayoutSettings
                    entityMetadataName="Client"
                    includeStandardFields={true}
                    questions={questions}
                    sectionParentKey={hostedFormClientContainerKey(form.id)}
                    label=""
                    allowEditOrder={form.customizable}
                    allowEditVisibility={form.customizable}
                    mode="alwaysEditing"
                    allowEditFields={form.customizable}
                    showContainerTitle={false}
                    enableNaryFormat={false}
                    allowCreateSections={false}
                    allowEditIcon="always"
                    allowEditTitle="always"
                    allowEditItemTitles={true}
                />
            </Transition>
        </div>
    );
};

interface HostedFormConfigurationProps {
    form: GetHostedFormsQuery["getHostedForms"][0];
    onRegionChange: (
        regionId: RegionId,
        userId: UserId,
        recipientUserIds: UserId[],
    ) => void;
    handleNameChange: (name: string) => void;
    handleStyleChange: (style: HostedFormStyle) => void;
    configureName: boolean;
}

const HostedFormConfiguration: React.FC<HostedFormConfigurationProps> = props => {
    const regions = useRegions();

    const { configureName, form, onRegionChange } = props;

    const hostedFormStyle = form.configuration.style ?? HostedFormStyle.Embedded;
    return (
        <div className="space-y-8">
            <div className="space-y-2">
                {configureName ? (
                    <AutosavingInput
                        initial={form.name ?? ""}
                        label="Name"
                        onCommit={props.handleNameChange}
                    />
                ) : null}
                <Radio
                    label="Form style"
                    options={[
                        {
                            key: HostedFormStyle.Embedded,
                            text: "Embed on my website",
                            helpText:
                                "Best used when hosted intake form is part of your website. Won't include logos or branding.",
                        },
                        {
                            key: HostedFormStyle.Standalone,
                            text: "Stand-alone page",
                            helpText:
                                "Best used when you want to send the direct link. Will include your logo.",
                        },
                    ]}
                    value={hostedFormStyle}
                    onChange={props.handleStyleChange}
                />
            </div>
            <ul className="space-y-8">
                {regions.map(region => (
                    <li key={region.id}>
                        <HostedFormRegionConfiguration
                            style={hostedFormStyle}
                            onRegionChange={onRegionChange}
                            form={form}
                            region={region}
                            multiRegion={regions.length > 1}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

interface HostedFormRegionConfigurationProps {
    region: TenantRegion;
    form: GetHostedFormsQuery["getHostedForms"][0];
    multiRegion: boolean;
    onRegionChange: (
        regionId: RegionId,
        userId: UserId,
        recipientUserIds: UserId[],
    ) => void;
    style: HostedFormStyle;
}

const HostedFormRegionConfiguration: React.FC<
    HostedFormRegionConfigurationProps
> = props => {
    const { region, form, multiRegion, onRegionChange, style } = props;

    const directUrl = `${window.location.origin}/hosted-form/${form.id}?regionId=${region.id}`;
    const embedUrl = `${window.location.origin}/hosted-form/${form.id}?regionId=${region.id}&mode=embed`;

    const users = useUsersForRegion(region.id);
    const currentRegion = form.configuration.regions?.find(a => a.regionId === region.id);
    const regionAssigneeUserId = currentRegion?.userId || null;
    const recipients = currentRegion?.recipientUserIds || [];

    return (
        <div className="space-y-3">
            {multiRegion ? <h3>{region.name}</h3> : null}
            <div>
                <UserSelect
                    label="Assignee for new clients"
                    value={regionAssigneeUserId}
                    onChange={userId =>
                        onRegionChange(region.id, userId as UserId, recipients)
                    }
                    includeEveryone={false}
                    includeUnassigned={true}
                />
            </div>
            <div>
                <MultiUserSelect
                    value={recipients}
                    onChange={recipientIds =>
                        onRegionChange(region.id, regionAssigneeUserId, recipientIds)
                    }
                    label="Notify these users of new clients"
                    viewableUsersMap={users}
                />
            </div>
            {style === HostedFormStyle.Embedded ? (
                <div>
                    <p>Embed code</p>
                    <CopyableLink
                        url={`<iframe style="width: 100%; height: 1800px; border: 0;" src="${embedUrl}" id="__SP_HostedForm_${form.id}"></iframe>`}
                    />
                </div>
            ) : null}
            {style !== HostedFormStyle.Embedded ? (
                <div>
                    <p>Direct link</p>
                    <CopyableLink url={directUrl} />
                </div>
            ) : null}
        </div>
    );
};
