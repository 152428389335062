import {
    ActionType,
    COMMUNITY_REFERRAL,
    CommunityId,
    Maybe,
    ReferenceBusinessId,
    ReferenceMap,
    parseEntityId,
} from "@sp-crm/core";
import { ReferralSourcePicker } from "components/references/select-reference/referral-source-picker";
import { AutosavingInput } from "components/ui/autosaving-input";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    GetOutboundClientReferralsQuery,
    MutationPatchClientReferralArgs,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useIsAllowed } from "store/selectors/hooks";
import { BylineSimple } from "../../byline/byline-simple";
import Shared from "../../shared";

interface ClientReferralEditProps {
    referral: GetOutboundClientReferralsQuery["getClientReferrals"][0];
    onChange: (params: MutationPatchClientReferralArgs) => void;
}

export const ClientReferralEdit: React.FC<ClientReferralEditProps> = props => {
    const { referral, onChange } = props;

    const handleDelete = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const confirmed = await fancyConfirm(
                "Delete Referral?",
                "Delete this referral? This is not reversable.",
                "Yes, delete referral",
                "Cancel",
            );
            if (confirmed) {
                onChange({ id: referral.id, clientId: referral.clientId, deleted: true });
            }
        },
        [referral, onChange],
    );

    const referralSourceMap: ReferenceMap = useMemo(() => {
        return {
            identifier: referral.communityId
                ? Maybe.fromValue(COMMUNITY_REFERRAL)
                : Maybe.none(),
            contact: referral.referenceContactId
                ? Maybe.fromValue(referral.referenceContactId)
                : Maybe.none(),
            business: referral.referenceBusinessId
                ? Maybe.fromValue(referral.referenceBusinessId)
                : referral.communityId
                ? Maybe.fromValue(
                      parseEntityId<ReferenceBusinessId>(referral.communityId),
                  )
                : Maybe.none(),
        };
    }, [referral]);

    const handleReferralSourcePicked = useCallback(
        (newReferralSourceMap: ReferenceMap) => {
            const identifier = newReferralSourceMap.identifier.getOrElse(null) || null;
            const businessId = newReferralSourceMap.business.getOrElse(null) || null;
            const contactId = newReferralSourceMap.contact.getOrElse(null) || null;

            if (identifier === COMMUNITY_REFERRAL) {
                onChange({
                    id: referral.id,
                    clientId: referral.clientId,
                    referenceContactId: contactId,
                    referenceBusinessId: null,
                    communityId: businessId
                        ? parseEntityId<CommunityId>(businessId)
                        : null,
                });
            } else {
                onChange({
                    id: referral.id,
                    clientId: referral.clientId,
                    referenceContactId: contactId,
                    referenceBusinessId: businessId,
                    communityId: null,
                });
            }
        },
        [referral, onChange],
    );

    const deleteAllowed = useIsAllowed(ActionType.DeleteReferral);

    return (
        <div className="referral-edit input-form-block">
            <div className="byline">
                <BylineSimple entity={referral} />
            </div>
            {deleteAllowed ? (
                <div className="referral-edit-controls">
                    <SecondaryButton onClick={handleDelete}>Delete</SecondaryButton>
                </div>
            ) : null}
            <div>
                <Shared.DateInput
                    initial={referral.when}
                    onChange={newDate =>
                        onChange({
                            id: referral.id,
                            clientId: referral.clientId,
                            when: newDate,
                        })
                    }
                    showTime={false}
                    label="Date referred"
                />
            </div>
            <div className="my-2">
                <ReferralSourcePicker
                    value={referralSourceMap}
                    onChange={handleReferralSourcePicked}
                    exclude={["general"]}
                    label="Referred to"
                    showIcon={false}
                    clientId={referral.clientId}
                />
            </div>
            <div>
                <AutosavingInput
                    multiLine={true}
                    label="Notes"
                    initial={referral.notes}
                    onCommit={newVal =>
                        onChange({
                            id: referral.id,
                            clientId: referral.clientId,
                            notes: newVal,
                        })
                    }
                />
            </div>
        </div>
    );
};
