import { IsoTimestamp, UserId, nullEntityId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { Content, SectionHeader, Stage } from "components/layout";
import { UserSelect } from "components/shared/user-select";
import { SearchInput } from "components/ui/search-input";
import { Select } from "components/ui/select";
import {
    GetTasksv2Query,
    useGetTasksv2Query,
    useGetUsersForRegionQuery,
} from "generated/graphql";
import { produce } from "immer";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentUserId, useRegionId } from "store/selectors/hooks";
import { FilterableTaskTypes, taskDashboardSlice } from "store/slices/tasks-dashboard";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import {
    AnnotatedViewableTaskTypes,
    TaskOwnerViewableKind,
} from "../../../constants/tasks";
import { TaskListv2 } from "./task-list-v2";
import { TaskTypeSelect } from "./task-type-select";
import { Taskv2 } from "./types";

export const Tasksv2: React.FC<unknown> = props => {
    const currentUserId = useCurrentUserId();
    const taskFilter = useSelector((state: ApplicationState) => state.taskDashboard);
    const regionId = useRegionId();
    const { entity, taskType, query, completedAtTimestamp } = taskFilter;
    const {
        updateQuery,
        updateTypeFilter,
        updateEntityFilter,
        firstLoad,
        clearCompleted,
        selectUser,
    } = taskDashboardSlice.actions;
    React.useEffect(() => {
        dispatch(firstLoad(new Date().toISOString() as IsoTimestamp));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectUserFilter = (userId: UserId) => dispatch(selectUser(userId));

    React.useEffect(() => {
        if (
            currentUserId &&
            (taskFilter.selectedUserId === null ||
                taskFilter.selectedUserId === undefined)
        ) {
            onSelectUserFilter(currentUserId);
        }
    }, [currentUserId, onSelectUserFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    const queryParams = React.useMemo(() => {
        return {
            params: {
                regionId,
                completedAt:
                    completedAtTimestamp ?? (new Date().toISOString() as IsoTimestamp),
                ownerId: taskFilter.selectedUserId || nullEntityId<UserId>(),
            },
        };
    }, [regionId, completedAtTimestamp, taskFilter.selectedUserId]);
    const userTasks = useGetTasksv2Query(queryParams, {
        keepPreviousData: true,
    });
    const queryClient = useQueryClient();
    const modifyLocalTask = React.useCallback(
        (task: Taskv2) => {
            queryClient.setQueryData<GetTasksv2Query>(["getTasksv2", queryParams], data =>
                produce(data, draft => {
                    if (draft) {
                        const existingTask = draft.getTasksv2.find(
                            x => x.task.id === task.task.id,
                        );
                        if (existingTask) {
                            existingTask.task = task.task;
                            existingTask.entity = task.entity;
                        }
                    }
                }),
            );
        },
        [queryParams, queryClient],
    );
    const getUsers = useGetUsersForRegionQuery(
        { regionId },
        { ...stableQueryOptions(), enabled: !!regionId },
    );
    const dispatch = useDispatch();
    const onUpdateQuery = (q: string) => dispatch(updateQuery(q));
    const onChangeEntityFilter = (q: TaskOwnerViewableKind) =>
        dispatch(updateEntityFilter(q));
    const onChangeTypeFilter = (q: FilterableTaskTypes) => dispatch(updateTypeFilter(q));
    const clearCompletedAction = React.useCallback(() => {
        dispatch(clearCompleted(new Date().toISOString() as IsoTimestamp));
    }, [clearCompleted, dispatch]);
    const handleRefetch = React.useCallback(async () => {
        await userTasks.refetch();
    }, [userTasks]);

    const showClearCompleted = userTasks?.data?.getTasksv2?.some(x => x.task.done);

    return (
        <Stage>
            <SectionHeader title="Tasks" />
            <Content>
                <div className="flex space-x-2 items-center justify-between">
                    <div className="flex-1">
                        <SearchInput
                            label="Search"
                            type="text"
                            onChange={val => onUpdateQuery(val.target.value)}
                            value={query}
                            placeholder="Task Description"
                        />
                    </div>
                    <div className="flex-1">
                        <TaskTypeSelect
                            value={taskType}
                            onChange={onChangeTypeFilter}
                            allowAllTaskTypes
                            allowUnsetTaskType
                        />
                    </div>
                    <div className="flex-1">
                        <Select
                            label="Association"
                            onChange={e =>
                                onChangeEntityFilter(
                                    e.target.value as TaskOwnerViewableKind,
                                )
                            }
                            value={entity}>
                            {AnnotatedViewableTaskTypes.map(x => (
                                <option key={x.kind} value={x.kind}>
                                    {x.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="flex-1">
                        <UserSelect
                            label="Assigned to"
                            onChange={(userId: UserId) => onSelectUserFilter(userId)}
                            value={taskFilter.selectedUserId}
                            includeUnassigned
                            includeEveryone={false}
                        />
                    </div>
                </div>
            </Content>
            <div className="mt-2 lg:mt-4">
                <Content>
                    {showClearCompleted && (
                        <div className="mb-2">
                            <button
                                onClick={clearCompletedAction}
                                className="text-brand-500 text-sm hover:underline">
                                Hide completed tasks
                            </button>
                        </div>
                    )}
                    <QueryRenderer name="Tasksv2" query={userTasks}>
                        {data => (
                            <TaskListv2
                                entityFilter={entity}
                                queryString={query}
                                taskTypeFilter={taskType}
                                tasks={data.getTasksv2}
                                users={getUsers.data?.getUsersForRegion || []}
                                refetch={handleRefetch}
                                modifyLocalTask={modifyLocalTask}
                            />
                        )}
                    </QueryRenderer>
                </Content>
            </div>
        </Stage>
    );
};
