import {
    CalendarDate,
    ClientId,
    CurrencyInCents,
    genEntityId,
    stringToCurrencyInCents,
} from "@sp-crm/core";
import { InlineBanner } from "components/ui/inline-banner";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { Formik, FormikHelpers } from "formik";
import React, { MutableRefObject, Reducer, useReducer } from "react";
import {
    AdvancedSearchEntityType,
    CreateInvoiceMutationVariables,
    InvoiceLineItemInput,
    InvoicePaymentScheduleInput,
    useCreateInvoiceMutation,
} from "../../generated/graphql";
import { InvoiceInput } from "../clients/invoices";
import { MessageComposeInline } from "../messages/message-compose-internal";
import { PlacementMessageCompose } from "../messages/types";
import { PrimaryButton } from "../ui/primary-button";
import { SecondaryButton } from "../ui/secondary-button";
import { Spinner } from "../ui/spinner";
import { InvoiceDetailsEditor } from "./invoice-details-editor";
import { validatePaymentSchedule } from "./invoice-helpers";
import { InvoicePaymentScheduleErrors } from "./invoice-payment-schedule-errors";

interface Props {
    clientId: ClientId;
    invoiceInput: InvoiceInput;
    refetch: () => void;
}

const dismiss = () => 0;

interface ComponentState {
    state: "closed" | "open" | "submitting";
    errorMessage: string | null;
    lineItems: InvoiceLineItemInput[];
    paymentSchedule: InvoicePaymentScheduleInput | null;
}
type Action =
    | { type: "open" }
    | { type: "close" }
    | { type: "submit" }
    | { type: "submitFailure"; error: string | null }
    | { type: "submitSuccess" }
    | { type: "setLineItems"; lineItems: InvoiceLineItemInput[] }
    | { type: "setPaymentSchedule"; paymentSchedule: InvoicePaymentScheduleInput | null };

const initialState: ComponentState = {
    state: "closed",
    errorMessage: null,
    lineItems: [],
    paymentSchedule: null,
};
const reducer: Reducer<ComponentState, Action> = (p, a) => {
    switch (a.type) {
        case "close":
            return {
                ...p,
                ...initialState,
            };
        case "open":
            return {
                ...p,
                state: "open",
                lineItems: [
                    {
                        description: "",
                        quantity: 1,
                        id: genEntityId(),
                        unitPriceInCents: 0 as CurrencyInCents,
                    },
                ],
            };
        case "submit":
            return { ...p, state: "submitting" };
        case "submitSuccess":
            return {
                ...p,
                ...initialState,
            };
        case "submitFailure":
            return { ...p, state: "open", errorMessage: a.error };
        case "setLineItems":
            return { ...p, lineItems: a.lineItems };
        case "setPaymentSchedule":
            return { ...p, paymentSchedule: a.paymentSchedule };
    }
};

interface FormValues {
    serial: string;
    amount: string;
    status: string;
    sentDate: string | undefined;
    dueDate: string | undefined;
    generatePdf: "noop" | "email" | "pdf";
    notes: string;
    communityNameErrorOnly: null;
    moveDateErrorOnly: null;
    externalReference: string;
}

const generateInitialValues = (invoiceInput: InvoiceInput): FormValues => {
    return {
        serial: "",
        amount: "",
        status: "sent",
        sentDate: CalendarDate.fromDate(new Date()).toString(),
        dueDate: invoiceInput.defaultDueDate?.toString(),
        generatePdf: "noop",
        notes: invoiceInput.defaultNotes ?? "",
        communityNameErrorOnly: null,
        moveDateErrorOnly: null,
        externalReference: "",
    };
};

export const InvoiceCreateInternal = (props: Props) => {
    const { clientId, refetch, invoiceInput } = props;
    const clientIdStr = clientId;
    const [{ state, errorMessage, lineItems, paymentSchedule }, dispatch] = useReducer(
        reducer,
        initialState,
    );
    const closeAction = () => {
        dispatch({ type: "close" });
    };
    const openAction = () => {
        dispatch({ type: "open" });
    };
    const mutation = useCreateInvoiceMutation();
    const inlineEditorRef =
        React.useRef<PlacementMessageCompose>() as MutableRefObject<PlacementMessageCompose>;

    const submitForm = React.useCallback(
        (values: FormValues, helpers: FormikHelpers<FormValues>) => {
            const payload: CreateInvoiceMutationVariables = {
                clientId: clientIdStr,
                amountCents: stringToCurrencyInCents(values.amount),
                serial: values.serial === "" ? null : values.serial,
                status: values.status,
                sentDate: values.sentDate === "" ? null : values.sentDate,
                dueDate: values.dueDate === "" ? null : values.dueDate,
                generatePdf:
                    values.generatePdf === "email" || values.generatePdf === "pdf",
                notes: values.notes === "" ? null : values.notes,
                message:
                    values.generatePdf === "email" && inlineEditorRef.current
                        ? inlineEditorRef.current.getMessage()
                        : null,
                lineItems: lineItems,
                paymentSchedule: paymentSchedule,
                externalReference: values.externalReference,
            };

            (async () => {
                helpers.setSubmitting(true);
                dispatch({ type: "submit" });
                const result = await mutation.mutateAsync(payload);
                helpers.setSubmitting(false);
                if (result.createInvoice.errors) {
                    dispatch({
                        type: "submitFailure",
                        error:
                            result.createInvoice.errors.message ??
                            "Sorry, there were errors.",
                    });
                    result.createInvoice.errors.fields.forEach(e => {
                        helpers.setFieldError(
                            e.name === "amountCents" ? "amount" : e.name,
                            e.message,
                        );
                    });
                } else {
                    helpers.resetForm();
                    dispatch({ type: "submitSuccess" });
                    refetch();
                }
            })();
        },
        [dispatch, mutation, lineItems, paymentSchedule, clientIdStr, refetch],
    );

    const clientLinkedCorrectly = !!invoiceInput.communityName;

    const paymentScheduleValidationErrors = validatePaymentSchedule(
        lineItems,
        paymentSchedule,
    );

    return (
        <div>
            <div className="space-y-4">
                {clientLinkedCorrectly ? null : (
                    <InlineBanner type="warning">
                        This client is not yet associated with a community. Set the{" "}
                        <em>community</em> to ensure the invoice is correctly associated
                        with the community.
                    </InlineBanner>
                )}
                <PrimaryButton
                    onClick={e => {
                        e.preventDefault();
                        openAction();
                    }}>
                    Add Invoice
                </PrimaryButton>
            </div>
            <Panel
                headerText="Create Invoice"
                isOpen={state === "open" || state === "submitting"}
                onDismiss={closeAction}
                type={PanelType.extraLarge}>
                <div>
                    <Formik
                        onSubmit={submitForm}
                        initialValues={generateInitialValues(props.invoiceInput)}>
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            touched,
                            validateForm,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <KludgeForceValidate
                                    validateForm={validateForm}
                                    invoiceInput={invoiceInput}
                                />
                                <div className="space-y-6 border-b pb-8 border-solid text-base">
                                    {errorMessage ? (
                                        <div className="bg-red-200 text-red-700 p-4 rounded font-bold mb-2">
                                            {errorMessage}
                                        </div>
                                    ) : null}
                                    {errors.communityNameErrorOnly ? (
                                        <div className="bg-red-200 text-red-700 p-4 rounded font-bold mb-2">
                                            {errors.communityNameErrorOnly}
                                        </div>
                                    ) : null}
                                    {errors.moveDateErrorOnly ? (
                                        <div className="bg-red-200 text-red-700 p-4 rounded font-bold mb-2">
                                            {errors.moveDateErrorOnly}
                                        </div>
                                    ) : null}
                                    <div className="text-xl font-semibold">
                                        Invoice Type
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="form-radio"
                                                    name="generatePdf"
                                                    value="noop"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={
                                                        values.generatePdf === "noop"
                                                    }
                                                />
                                                <span className="ml-2">
                                                    Log invoice record only (don&apos;t
                                                    generate a PDF invoice)
                                                </span>
                                            </label>
                                        </div>
                                        <div className="">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="generatePdf"
                                                    value="email"
                                                    className="form-radio"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={
                                                        values.generatePdf === "email"
                                                    }
                                                />
                                                <span className="ml-2">
                                                    Email PDF invoice
                                                </span>
                                            </label>
                                        </div>
                                        <div className="">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="generatePdf"
                                                    value="pdf"
                                                    className="form-radio"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.generatePdf === "pdf"}
                                                />
                                                <span className="ml-2">
                                                    Generate a new PDF invoice
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <InvoiceDetailsEditor
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        lineItems={lineItems}
                                        onLineItemsChange={lineItems =>
                                            dispatch({ type: "setLineItems", lineItems })
                                        }
                                        paymentSchedule={paymentSchedule}
                                        onPaymentScheduleChange={paymentSchedule => {
                                            dispatch({
                                                type: "setPaymentSchedule",
                                                paymentSchedule,
                                            });
                                        }}
                                    />
                                    {values.generatePdf === "email" ? (
                                        <div>
                                            <MessageComposeInline
                                                entityId={clientId}
                                                entityType={
                                                    AdvancedSearchEntityType.Client
                                                }
                                                onDismiss={dismiss}
                                                requestSpecialMessageExperience="invoice"
                                                ref={inlineEditorRef}
                                            />
                                        </div>
                                    ) : null}
                                    <InvoicePaymentScheduleErrors
                                        errors={paymentScheduleValidationErrors}
                                    />
                                    <div className="flex space-x-4 my-4">
                                        <PrimaryButton
                                            type="submit"
                                            disabled={
                                                state === "submitting" ||
                                                paymentScheduleValidationErrors.length > 0
                                            }>
                                            {state === "submitting" ? (
                                                <Spinner />
                                            ) : values.generatePdf === "email" ? (
                                                "Send"
                                            ) : (
                                                "Save"
                                            )}
                                        </PrimaryButton>
                                        <SecondaryButton
                                            onClick={e => {
                                                e.preventDefault();
                                                closeAction();
                                            }}>
                                            Cancel
                                        </SecondaryButton>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Panel>
        </div>
    );
};

interface KludgeForceValidateProps {
    validateForm: () => void;
    invoiceInput: InvoiceInput;
}

const KludgeForceValidate: React.FC<KludgeForceValidateProps> = props => {
    React.useEffect(() => {
        props.validateForm();
    }, [props.invoiceInput]); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
};
