import * as C from "@sp-crm/core";
import { QuestionId } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SearchInput } from "components/ui/search-input";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import * as React from "react";
import { inAdvancedMode } from "../../../util/browser";
import { handleEvent } from "../../../util/user-events";
import { Header } from "../../header";
import { SliderSegment } from "../../shared/slider-segment";
import { SearchCalloutButtons } from "./search-callout-buttons";
import { SearchQuestion, renderable } from "./search-question";
import { ISearchAnswer, ISearchPrice, searchRangeMax, searchRangeMin } from "./util";

interface SearchMoreFiltersProps {
    content: JSX.Element;
    answers: ISearchAnswer[];
    questions: C.IQuestion[];
    bothGenders: boolean;
    femaleOnly: boolean;
    maleOnly: boolean;
    genderNotSet: boolean;
    contractNoState: boolean;
    contractPending: boolean;
    contractActive: boolean;
    contractOneOff: boolean;
    contractNone: boolean;
    contractTerminated: boolean;
    licensePending: boolean;
    licenseActive: boolean;
    licenseOnProbation: boolean;
    licenseClosed: boolean;
    licenseNotApplicable: boolean;
    licenseNotSet: boolean;
    minCapacity?: number;
    maxCapacity?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFilterSubmitted: (params: any) => void;
    rejectedQuestions?: string[];
    matchLowScore?: string;
    matchHighScore?: string;
    matchRule?: string;
    clear: () => void;
}

interface SearchMoreFiltersState {
    answers: ISearchAnswer[];
    showPanel: boolean;
    bothGenders: boolean;
    femaleOnly: boolean;
    maleOnly: boolean;
    genderNotSet: boolean;
    contractNoState: boolean;
    contractPending: boolean;
    contractActive: boolean;
    contractOneOff: boolean;
    contractNone: boolean;
    contractTerminated: boolean;
    licensePending: boolean;
    licenseActive: boolean;
    licenseOnProbation: boolean;
    licenseClosed: boolean;
    licenseNotApplicable: boolean;
    licenseNotSet: boolean;
    minCapacity?: number;
    maxCapacity?: number;
    matchLowScore?: string;
    matchHighScore?: string;
    matchRule?: string;
    searchString: string;
}

export class SearchMoreFilters extends React.Component<
    SearchMoreFiltersProps,
    SearchMoreFiltersState
> {
    constructor(props: SearchMoreFiltersProps) {
        super(props);
        // TODO: This is a bad design pattern to set the initial state based on the props. This means
        // that if the state changes in the container component (i.e. when "Clear All Filters" is
        // pressed), our internal state won't get reset automatically/by default since we're only
        // "seeding" our state with the initial props and not actually updating our state with those
        // props. I'm working around this issue right now by always resetting our state to the values
        // from props at the very moment the panel is toggled ON. Once the panel is open, or being closed,
        // our internal state is the correct source of truth, and the props passed into us will get updated
        // before the next time we are shown again. So, this works for now, but it probably isn't the ideal
        // design pattern.
        // INSTEAD: We should get all of this from Redux state. It already has it for conducting the searches.
        // Code cleanup here is tracked by CRM-904
        this.state = this.initialState();
        this.onSubmit = this.onSubmit.bind(this);
        this.onClear = this.onClear.bind(this);
        this.questionSection = this.questionSection.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.updateAdvancedSearch = this.updateAdvancedSearch.bind(this);
        this.answerForQuestion = this.answerForQuestion.bind(this);
    }

    initialState(): SearchMoreFiltersState {
        return {
            showPanel: false,
            answers: this.props.answers || ([] as ISearchAnswer[]),
            bothGenders: this.props.bothGenders,
            femaleOnly: this.props.femaleOnly,
            maleOnly: this.props.maleOnly,
            genderNotSet: this.props.genderNotSet,
            contractNoState: this.props.contractNoState,
            contractPending: this.props.contractPending,
            contractActive: this.props.contractActive,
            contractOneOff: this.props.contractOneOff,
            contractNone: this.props.contractNone,
            contractTerminated: this.props.contractTerminated,
            licensePending: this.props.licensePending,
            licenseActive: this.props.licenseActive,
            licenseOnProbation: this.props.licenseOnProbation,
            licenseClosed: this.props.licenseClosed,
            licenseNotApplicable: this.props.licenseNotApplicable,
            licenseNotSet: this.props.licenseNotSet,
            minCapacity: this.props.minCapacity,
            maxCapacity: this.props.maxCapacity,
            matchLowScore: "",
            matchHighScore: "",
            matchRule: "",
            searchString: "",
        };
    }

    togglePanel(showPanel?: boolean) {
        const flag = showPanel !== undefined ? showPanel : !this.state.showPanel;

        if (flag) {
            handleEvent("community-search-advanced-filter");
            // Whenever we're going to *show* the panel, the props passed in should have
            // the best/correct state, so let's use that.
            const state = this.initialState();
            state.showPanel = true;
            this.setState(state);
        } else {
            this.setState({ ...this.state, showPanel: flag });
        }
    }

    getQuestions(): C.IQuestion[] {
        return (this.props.questions || [])
            .filter(q => this.props.rejectedQuestions.indexOf(q.id) === -1)
            .filter(q => {
                if (this.state.searchString.length > 0) {
                    return (
                        q.title
                            .toLowerCase()
                            .indexOf(this.state.searchString.toLowerCase()) > -1 ||
                        (q.prompts || []).some(
                            p =>
                                (p.title ?? "")
                                    .toLowerCase()
                                    .indexOf(this.state.searchString.toLowerCase()) > -1,
                        )
                    );
                }
                return true;
            });
    }

    getClearedAnswers(): ISearchAnswer[] {
        if (!this.props.rejectedQuestions) {
            return [] as ISearchAnswer[];
        }

        return (this.state.answers || []).filter(
            answer => this.props.rejectedQuestions.indexOf(answer.questionId) > -1,
        );
    }

    updateAdvancedSearch(a: ISearchAnswer) {
        const answers: ISearchAnswer[] = this.state.answers.filter(
            x => x.questionId != a.questionId,
        );
        answers.push(a);
        const newState: SearchMoreFiltersState = { ...this.state, answers };
        this.setState(newState);
    }

    answerForQuestion(q: C.IQuestion): ISearchAnswer {
        return (
            this.state.answers.find(x => x.questionId == q.id) || {
                selectionAnswer: [],
                questionId: q.id,
                booleanAnswer: "na",
            }
        );
    }

    onDismiss() {
        const newState = { ...this.state, showPanel: false };
        this.setState(newState, () => {
            this.onSubmit(newState);
        });
    }

    onSubmit(state: SearchMoreFiltersState) {
        this.props.onFilterSubmitted({
            answers: state.answers,
            bothGenders: state.bothGenders,
            femaleOnly: state.femaleOnly,
            maleOnly: state.maleOnly,
            genderNotSet: state.genderNotSet,
            contractNoState: state.contractNoState,
            contractPending: state.contractPending,
            contractActive: state.contractActive,
            contractOneOff: state.contractOneOff,
            contractNone: state.contractNone,
            contractTerminated: state.contractTerminated,
            licensePending: state.licensePending,
            licenseActive: state.licenseActive,
            licenseOnProbation: state.licenseOnProbation,
            licenseClosed: state.licenseClosed,
            licenseNotApplicable: state.licenseNotApplicable,
            licenseNotSet: state.licenseNotSet,
            minCapacity: state.minCapacity,
            maxCapacity: state.maxCapacity,
            matchLowScore: state.matchLowScore,
            matchHighScore: state.matchHighScore,
            matchRule: state.matchRule,
        });
    }

    onClear() {
        this.props.clear();
        const newState: SearchMoreFiltersState = {
            answers: this.getClearedAnswers(),
            bothGenders: false,
            femaleOnly: false,
            maleOnly: false,
            genderNotSet: false,
            showPanel: false,
            contractNoState: false,
            contractPending: false,
            contractActive: false,
            contractOneOff: false,
            contractNone: false,
            contractTerminated: false,
            licensePending: false,
            licenseActive: false,
            licenseOnProbation: false,
            licenseClosed: false,
            licenseNotApplicable: false,
            licenseNotSet: false,
            minCapacity: null,
            maxCapacity: null,
            searchString: "",
        };
        this.setState(newState);
    }

    questionSection(
        category: C.QuestionCategories,
        extraQuestions?: JSX.Element[],
    ): JSX.Element {
        const theQuestions: C.IQuestion[] = this.getQuestions();
        const order = (questionA: C.IQuestion, questionB: C.IQuestion): number =>
            (questionA.order || 0) - (questionB.order || 0);
        const boxes = theQuestions
            .filter(
                q =>
                    q.searchBehavior !== C.QuestionBehavior.alwaysHide &&
                    q.category === category &&
                    renderable(q),
            )
            .sort(order)
            .map(q =>
                q.questionType === C.QuestionType.binary ||
                q.questionType === C.QuestionType.nary ? (
                    <SearchQuestion
                        searchAnswer={this.answerForQuestion(q)}
                        onChange={e => this.updateAdvancedSearch(e)}
                        key={q.id}
                        question={q}
                    />
                ) : q.questionType === C.QuestionType.currency ||
                  q.questionType === C.QuestionType.range ? (
                    this.rangeQuestion(q)
                ) : null,
            );

        if (boxes.length > 0 || (extraQuestions && extraQuestions.length > 0)) {
            return (
                <div>
                    <Header
                        headerSize="minor"
                        iconSize="small"
                        iconName={C.questionMetadata[category].icon}>
                        {C.questionMetadata[category].communityHeading}
                    </Header>
                    <div className="grid grid-cols-2 gap-4">
                        {boxes}
                        {extraQuestions}
                    </div>
                </div>
            );
        }
        return null;
    }

    getSearchAnswerByQuestionId(questionId: QuestionId, questionType: C.QuestionType) {
        let answer = this.state.answers.find(x => x.questionId === questionId);

        if (!answer) {
            answer = {
                questionId: questionId,
                booleanAnswer: "na",
                selectionAnswer: [],
                rangeAnswer: this.getCurrencyValues(questionId, questionType),
            };
        }
        return answer;
    }

    onCurrencyValueChanged(
        questionId: QuestionId,
        questionType: C.QuestionType,
        values: [number, number],
    ) {
        const answer = this.getSearchAnswerByQuestionId(questionId, questionType);

        const newAnswer = produce(answer, draft => {
            draft.rangeAnswer.min = values[0];
            draft.rangeAnswer.max = values[1];
            draft.rangeAnswer.skipMax = values[1] === searchRangeMax;
        });

        this.updateAdvancedSearch(newAnswer);
    }

    onCurrencyCheckboxChanged(
        questionId: QuestionId,
        ev: React.FormEvent<HTMLElement>,
        checked: boolean,
    ) {
        const answer = this.getSearchAnswerByQuestionId(
            questionId,
            C.QuestionType.currency,
        );

        const newAnswer = produce(answer, draft => {
            draft.rangeAnswer.includePriceUndefined = checked;
        });

        this.updateAdvancedSearch(newAnswer);
    }

    getCurrencyValues(
        questionId: QuestionId,
        questionType: C.QuestionType,
    ): ISearchPrice {
        const answer = this.state.answers.find(x => x.questionId === questionId);
        const defaultRangeAnswer = {
            min: searchRangeMin,
            max: searchRangeMax,
            skipMax: true,
            includePriceUndefined: questionType === C.QuestionType.currency,
            isOverlap: questionType !== C.QuestionType.currency,
        };

        return answer ? answer.rangeAnswer : defaultRangeAnswer;
    }

    rangeQuestion(question: C.IQuestion): JSX.Element {
        return (
            <div className="col-span-2" key={question.id}>
                <span className="block font-semibold">{question.title}</span>
                <div className="max-w-lg">
                    <SliderSegment
                        min={searchRangeMin}
                        max={searchRangeMax}
                        values={this.getCurrencyValues(
                            question.id,
                            +question.questionType,
                        )}
                        onChange={values =>
                            this.onCurrencyValueChanged(
                                question.id,
                                +question.questionType,
                                values,
                            )
                        }
                    />
                </div>
            </div>
        );
    }

    binaryOption(propName: string, label: string): JSX.Element {
        return (
            <Checkbox
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                checked={(this.state as any)[`${propName}`]}
                onChange={x =>
                    this.setState({
                        ...this.state,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                        [`${propName}`]: (x.target as any).checked,
                    })
                }
                label={label}
            />
        );
    }

    numberOption(propName: string, label: string): JSX.Element {
        return (
            <div className="number-option search-question">
                <AutosavingInput
                    onCommit={newVal =>
                        this.setState({
                            ...this.state,
                            [`${propName}`]: newVal.length > 0 ? Number(newVal) : null,
                        })
                    }
                    type="number"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                    initial={(this.state as any)[`${propName}`]}
                    label={label}
                />
            </div>
        );
    }

    advancedSection(): JSX.Element {
        if (inAdvancedMode()) {
            return (
                <div className="space-x-2 items-center flex">
                    <Input
                        value={this.state.matchLowScore}
                        label="Match Low Score"
                        onChange={e => this.setState({ matchLowScore: e.target.value })}
                    />
                    <Input
                        value={this.state.matchHighScore}
                        label="Match High Score"
                        onChange={e => this.setState({ matchHighScore: e.target.value })}
                    />
                    <Input
                        value={this.state.matchRule}
                        label="Match Rule"
                        onChange={e => this.setState({ matchRule: e.target.value })}
                    />
                </div>
            );
        }
        return null;
    }

    private search(): JSX.Element {
        return (
            <div className="flex space-x-2 items-end justify-between">
                <div className="flex-1">
                    <SearchInput
                        value={this.state.searchString}
                        onChange={e => this.setState({ searchString: e.target.value })}
                        label="Search for filter"
                    />
                </div>
                <div>
                    <SecondaryButton
                        onClick={() => this.setState({ searchString: "" })}
                        disabled={!this.state.searchString}>
                        Clear
                    </SecondaryButton>
                </div>
            </div>
        );
    }

    private filterExtraQuestions(
        questions: { element: JSX.Element; plaintext: string }[],
    ): JSX.Element[] {
        if (this.state.searchString.length > 0) {
            return questions
                .filter(
                    q =>
                        q.plaintext
                            .toLowerCase()
                            .indexOf(this.state.searchString.toLowerCase()) > -1,
                )
                .map(q => q.element);
        }
        return questions.map(q => q.element);
    }

    render() {
        const genderQuestions = this.filterExtraQuestions([
            {
                element: (
                    <div className="search-question" key="male-female">
                        <h3 className="font-semibold">Gender restrictions</h3>
                        {this.binaryOption("bothGenders", "Accepts male and female")}
                        {this.binaryOption("femaleOnly", "Female only")}
                        {this.binaryOption("maleOnly", "Male only")}
                        {this.binaryOption(
                            "genderNotSet",
                            "Unspecified on Community Profile",
                        )}
                    </div>
                ),
                plaintext:
                    "Gender restrictions Unspecified on Community Profile Male only Female only Accepts male and female",
            },
            {
                element: (
                    <div className="search-question" key="units-or-capacity">
                        {this.numberOption(
                            "minCapacity",
                            "Total beds greater than or equal to",
                        )}
                        {this.numberOption(
                            "maxCapacity",
                            "Total beds less than or equal to",
                        )}
                    </div>
                ),
                plaintext:
                    "Total beds greater than or equal to Total beds less than or equal to",
            },
        ]);

        const contractAndLicenseStatus = this.filterExtraQuestions([
            {
                element: (
                    <div className="search-question" key="contract-status">
                        <h3 className="font-semibold">Contract Status</h3>
                        {this.binaryOption("contractActive", "Active Contract")}
                        {this.binaryOption("contractOneOff", "One Off / Honor Contract")}
                        {this.binaryOption("contractPending", "Contract Pending")}
                        {this.binaryOption("contractNone", "No Contract")}
                        {this.binaryOption("contractTerminated", "Terminated")}
                        {this.binaryOption(
                            "contractNoState",
                            "Unspecified on Community Profile",
                        )}
                    </div>
                ),
                plaintext:
                    "Contract Status Active Contract One Off / Honor Contract Contract Pending No Contract Unspecified on Community Profile",
            },
            {
                element: (
                    <div className="search-question" key="license-status">
                        <h3 className="font-semibold">State License Status</h3>
                        <div>
                            By default, &quot;on probation&quot; and &quot;closed&quot;
                            facilities are <i>not displayed</i> unless explicitly checked
                            below.
                        </div>
                        {this.binaryOption("licensePending", "Pending")}
                        {this.binaryOption("licenseActive", "Active")}
                        {this.binaryOption("licenseOnProbation", "On Probation")}
                        {this.binaryOption("licenseClosed", "Closed")}
                        {this.binaryOption(
                            "licenseNotApplicable",
                            "License Not Applicable",
                        )}
                        {this.binaryOption(
                            "licenseNotSet",
                            "Unspecified on Community Profile",
                        )}
                    </div>
                ),
                plaintext:
                    "State License Status Pending On Probation Active Closed License Not Applicable Unspecified on Community Profile",
            },
        ]);

        return (
            <div className="search-more-filters">
                <div
                    className="text-lg cursor-pointer"
                    onClick={() => this.togglePanel()}>
                    {this.props.content}
                </div>
                <div>
                    <Panel
                        isOpen={this.state.showPanel}
                        isLightDismiss={true}
                        onDismiss={() => this.onDismiss()}
                        type={PanelType.largeFixed}
                        className="search-more-filters-panel"
                        headerText="More filters">
                        <div className="space-y-8">
                            {this.search()}
                            {this.questionSection(C.QuestionCategories.type)}
                            {this.questionSection(C.QuestionCategories.finance)}
                            {this.questionSection(C.QuestionCategories.staff)}
                            {this.questionSection(C.QuestionCategories.services)}
                            {this.questionSection(C.QuestionCategories.activity)}
                            {this.questionSection(
                                C.QuestionCategories.otherDetails,
                                genderQuestions,
                            )}
                            {this.questionSection(C.QuestionCategories.amenity)}
                            {this.questionSection(
                                C.QuestionCategories.contract,
                                contractAndLicenseStatus,
                            )}
                            {this.questionSection(C.QuestionCategories.flexible)}
                            {this.advancedSection()}
                            <SearchCalloutButtons
                                onSubmit={() => this.onDismiss()}
                                onClear={this.onClear}
                            />
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }
}
