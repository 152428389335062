import {
    CommunityId,
    ILocation,
    Localization,
    RegionId,
    nullEntityId,
} from "@sp-crm/core";
import { ClientGraphqlError } from "backend/fetcher";
import { InlineBanner } from "components/ui/inline-banner";
import { Spinner } from "components/ui/spinner";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useCommunityCardQuestionIds, useFeature } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { pluralize } from "util/pluralize";
import {
    CommunitySearchQuery,
    CommunitySearchRequest,
    useCommunitySearchQuery,
} from "../../generated/graphql";
import { CommunitySearchInstance } from "../../store/reducers/community-search";
import { ResponsiveMode } from "../../store/reducers/responsive";
import { currentLocale } from "../../store/selectors/regions";
import { Error } from "../error";
import { useDebouncedValue } from "../shared/hooks";
import { ClientCommunityBulkAdd } from "./client-community-bulk-add";
import { CommunityCard, CommunityCardExperience } from "./community-card/community-card";
import { CommunitySearchResultsMap } from "./community-map/community-search-results-map";
import { HoveredCommunity } from "./community-map/types";
import { CommunitySearchPagination } from "./community-search-pagination";
import { CommunitySearchResultsProps, ListableCommunity } from "./props";
import { searchRangeMax, searchRangeMin } from "./search-bar/util";
import { ClientCommunityRelationship } from "./types";

const staleTime = 1000 * 60 * 10; // 10m

const locationToCoordinates = (location: ILocation): { lat: string; lng: string } => ({
    lng: location.loc[0].toString(),
    lat: location.loc[1].toString(),
});

const searchInstanceToParams = (
    locale: Localization,
    input: CommunitySearchInstance,
    includeLicenses: boolean,
): Omit<CommunitySearchRequest, "questionIds"> => {
    const booleans: string[] = [];
    const nary: string[] = [];
    const rangeFilters: CommunitySearchRequest["rangeFilters"] = [];
    input.advancedAnswers.forEach(a => {
        if (a.booleanAnswer === "true") {
            booleans.push(a.questionId);
        } else if (a.selectionAnswer.length > 0) {
            nary.push(`${a.questionId}:${a.selectionAnswer.join(",")}`);
        } else if (a.rangeAnswer) {
            rangeFilters.push({
                min: isNaN(a.rangeAnswer.min) ? searchRangeMin : a.rangeAnswer.min,
                max: isNaN(a.rangeAnswer.max) ? searchRangeMax : a.rangeAnswer.max,
                questionId: a.questionId,
            });
        }
    });

    const contract: string[] = [];
    if (input.contractActive) contract.push("active");
    if (input.contractNone) contract.push("none");
    if (input.contractTerminated) contract.push("terminated");
    if (input.contractNoState) contract.push("NULL");
    if (input.contractOneOff) contract.push("oneOff");
    if (input.contractPending) contract.push("pending");

    const gender: string[] = [];
    if (input.bothGenders) gender.push("both");
    if (input.maleOnly) gender.push("male");
    if (input.femaleOnly) gender.push("female");
    if (input.genderNotSet) gender.push("NULL");

    const license: string[] = [];
    if (input.licensePending) license.push("pending");
    if (input.licenseActive) license.push("active");
    if (input.licenseOnProbation) license.push("onProbation");
    if (input.licenseClosed) license.push("closed");
    if (input.licenseNotApplicable) license.push("notApplicable");
    if (input.licenseNotSet) license.push("NULL");
    const matchType = input.specificZip
        ? "zip"
        : input.specificCity
        ? "city"
        : input.specificCounty
        ? "county"
        : input.maxDistance && (input.zip || input.city || input.state || input.address)
        ? "distance"
        : null;

    const bounds: CommunitySearchRequest["bounds"] = input.freeMapCoordinates
        ? {
              northWest: locationToCoordinates(input.freeMapCoordinates.northWest),
              southEast: locationToCoordinates(input.freeMapCoordinates.southEast),
          }
        : null;
    return {
        page: input.clientSidePaginationOnly ? 0 : input.pageNumber,
        perPage: input.clientSidePaginationOnly ? 9999 : input.perPage,
        includeUnrated: input.rating.includeUnrated,
        rating: input.rating.value,
        searchString: input.searchText,
        booleans,
        nary,
        sortKey: input.sort,
        contract: contract.length > 0 ? contract.join(",") : null,
        license: license.length > 0 ? license.join(",") : null,
        priceMax: input.price?.max ?? 9999999,
        priceMin: input.price?.min ?? 0,
        includeNoPrice: input.price?.includePriceUndefined ?? true,
        regionId: input.regionId ?? nullEntityId<RegionId>(),
        zip: input.zip,
        address: input.address,
        city: input.city,
        state: input.state,
        county: matchType === "county" ? input.county : "",
        distance:
            input.maxDistance === undefined
                ? undefined
                : Math.ceil(locale.conversions.toMeters(input.maxDistance)),
        matchType,
        hideDontShow: !!input.hideDontShow,
        matchLowScore: input.matchLowScore,
        matchHighScore: input.matchHighScore,
        matchRule: input.matchRule,
        bedCountMin: input.minCapacity,
        bedCountMax: input.maxCapacity,
        rangeFilters,
        bounds,
        gender,
        includeLicenses,
    };
};

export const CommunitySearchResults: React.FC<CommunitySearchResultsProps> = props => {
    const responsiveMode: ResponsiveMode = useSelector(
        (appState: ApplicationState) => appState.responsive.mode,
    );
    const [hoveredCommunity, setHoveredCommunity] =
        React.useState<HoveredCommunity | null>(null);
    const liveSearchParams: CommunitySearchInstance = useSelector(
        (appState: ApplicationState) =>
            appState.communitySearch.searches[props.searchKey],
    );
    const [searchInstance] = useDebouncedValue(liveSearchParams, 333, { leading: true });
    const locale = useSelector((appState: ApplicationState) => currentLocale(appState));
    const includeLicenses = useFeature("discreteLicenseRecords");
    const searchParams = React.useMemo(
        () =>
            searchInstance
                ? searchInstanceToParams(locale, searchInstance, includeLicenses)
                : null,
        [locale, searchInstance, includeLicenses],
    );
    if (!searchParams) return null;
    return (
        <div className="w-full flex community-list collapsible">
            <div className="full-width">
                <CommunitySearchResultsList
                    {...props}
                    searchParams={searchParams}
                    setHoveredCommunity={setHoveredCommunity}
                    communitySearch={searchInstance}
                />
            </div>
            {responsiveMode >= ResponsiveMode.large ? (
                <CommunitySearchResultsMap
                    {...props}
                    searchParams={searchParams}
                    hoveredCommunity={hoveredCommunity}
                />
            ) : null}
        </div>
    );
};

export const CommunitySearchResultsList: React.FC<
    CommunitySearchResultsProps & {
        setHoveredCommunity: (community: HoveredCommunity | null) => void;
        searchParams: Omit<CommunitySearchRequest, "questionIds">;
        communitySearch: CommunitySearchInstance;
    }
> = props => {
    const {
        communitySearch,
        clientCommunityRelationshipProvider,
        setHoveredCommunity,
        searchParams,
        client,
    } = props;
    const requestedQuestionIds = useCommunityCardQuestionIds();
    const query = useCommunitySearchQuery(
        {
            params: {
                ...searchParams,
                includeQuestionIds: requestedQuestionIds,
            },
        },
        {
            staleTime,
        },
    );
    const [data, setData] = React.useState<CommunitySearchQuery | null>(null);
    React.useEffect(() => {
        if (query && query.data && !query.isError) {
            setData(query.data);
        }
    }, [query, query.data]);
    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [searchParams.page]);

    if (query.isLoading && !data) {
        return <Spinner />;
    }

    if (query.error) {
        const gqlError = query.error as ClientGraphqlError;
        if (
            typeof gqlError.hasErrorType === "function" &&
            gqlError.hasErrorType("INVALID_RECORD")
        ) {
            return (
                <InlineBanner type="warning">
                    The location data provided does not correspond to a real-world map
                    location. Please review your location search and try again.
                </InlineBanner>
            );
        }

        return (
            <Error componentName="NextgenFilteredCommunityList">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction */}
                {(query.error as any).message}
            </Error>
        );
    }

    if (!data) {
        return <Spinner />;
    }

    let hits = data.communitySearch.hits;

    if (communitySearch.clientSidePaginationOnly) {
        hits = hits.slice(
            communitySearch.pageNumber * communitySearch.perPage,
            communitySearch.pageNumber * communitySearch.perPage +
                communitySearch.perPage,
        );
    }

    const geoReference: ILocation | null = data.communitySearch.coordinates
        ? {
              loc: [
                  parseFloat(data.communitySearch.coordinates.lng),
                  parseFloat(data.communitySearch.coordinates.lat),
              ],
          }
        : null;

    const total = data?.communitySearch?.total ?? 0;
    return (
        <>
            {total > 0 ? (
                <div className="flex items-center justify-between pb-2 lg:pb-4">
                    <div className="text-sm font-bold">
                        {total} {pluralize(total, "community", "communities")} found
                    </div>
                    {client ? (
                        <ClientCommunityBulkAdd
                            total={total}
                            searchParams={searchParams}
                            questionIds={requestedQuestionIds}
                            client={client}
                        />
                    ) : null}
                </div>
            ) : null}
            <ListCommunity
                geoReference={geoReference}
                communities={hits}
                clientCommunityRelationshipProvider={clientCommunityRelationshipProvider}
                setHoveredCommunity={setHoveredCommunity}
            />
            <CommunitySearchPagination
                total={query.data?.communitySearch?.total}
                searchKey={props.searchKey}
            />
        </>
    );
};

interface CommunityRowProps {
    onHover: (community: ListableCommunity) => void;
    onEndHover: () => void;
    clientCommunityRelationshipProvider?: (
        communityId: CommunityId,
    ) => ClientCommunityRelationship;
    community: ListableCommunity;
    geoReference: ILocation;
}

const CommunityRow: React.FC<CommunityRowProps> = props => {
    const {
        community,
        clientCommunityRelationshipProvider,
        geoReference,
        onHover,
        onEndHover,
    } = props;

    const handleMouseEnter = React.useCallback(() => {
        onHover(community);
    }, [community, onHover]);

    const handleMouseLeave = React.useCallback(() => {
        onEndHover();
    }, [onEndHover]);

    return (
        <li key={community.id}>
            <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {clientCommunityRelationshipProvider ? (
                    <CommunityCard
                        clientCommunityRelationship={clientCommunityRelationshipProvider(
                            community.id,
                        )}
                        community={community}
                        experience={CommunityCardExperience.ClientMatchSearch}
                        geoReference={geoReference}
                    />
                ) : (
                    <CommunityCard
                        community={community}
                        experience={CommunityCardExperience.GlobalSearch}
                        geoReference={geoReference}
                    />
                )}
            </span>
        </li>
    );
};

interface ListCommunityProps {
    communities: ListableCommunity[];
    geoReference: ILocation;
    clientCommunityRelationshipProvider?: (
        communityId: CommunityId,
    ) => ClientCommunityRelationship;

    setHoveredCommunity: (community: HoveredCommunity | null) => void;
}

export const ListCommunity: React.FC<ListCommunityProps> = props => {
    const {
        geoReference,
        clientCommunityRelationshipProvider,
        communities,
        setHoveredCommunity,
    } = props;

    const handleHover = React.useCallback(
        (community: ListableCommunity) => {
            const location =
                community.lat && community.lng
                    ? { lat: parseFloat(community.lat), lng: parseFloat(community.lng) }
                    : undefined;
            setHoveredCommunity({ communityId: community.id, location });
        },
        [setHoveredCommunity],
    );

    const handleEndHover = React.useCallback(() => {
        setHoveredCommunity(null);
    }, [setHoveredCommunity]);

    const communityRows = useMemo(() => {
        return (communities || []).map(c => (
            <CommunityRow
                key={c.id}
                community={c}
                geoReference={geoReference}
                clientCommunityRelationshipProvider={clientCommunityRelationshipProvider}
                onHover={handleHover}
                onEndHover={handleEndHover}
            />
        ));
    }, [
        communities,
        geoReference,
        clientCommunityRelationshipProvider,
        handleHover,
        handleEndHover,
    ]);

    return <ul className="space-y-2 lg:space-y-4">{communityRows}</ul>;
};
